<template>
  <router-view/>
  <div class="frontend-version">Front v.{{version}}</div>
</template>



<script>
export default {
  data() {
      return {
        version: '1.2.240809'
      }
    }
}
</script>

<style lang="css">
@import "main.css";
.frontend-version {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #ccc;
  padding: 8px;
  font-size: 14px;
}
.pagination {
  display: flex;
  justify-content: flex-end;
}
.page-item {
  display: inline-flex;
  color: #333;
  border: 1px solid #e6e6e6;
  border-radius: .2em;
  margin: 0 .2em 1em 0;
}
.page-item.active {
  background: #e6e6e6;
}
.page-item.disabled {
  background: 0 0;
  border: 0;
  cursor: not-allowed;
}
.page-link {
  padding: .1em .35em;
  color: inherit;
  text-decoration: none;
}
</style>
