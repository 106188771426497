<template>
  <div class="content-wrap">
    <header-main :title_name="title_name"></header-main>
    <section class="content content-wrapper logs-page">
      <div class="logs-filter__wrap">
        <div class="logs-filter">
          <div class="logs-filter__date">
            Дата создания
            <span class="logs-filter__date-wrap">
                <span v-if="range.start">с</span>
              <date-picker
                v-model="range.start"
                timezone="UTC"
                mode="date"
                :max-date="new Date()"
                :model-config="modelConfig"
              >
                <template v-slot="{ inputValue, inputEvents, togglePopover }">
                  <input
                    type="button"
                    @click.stop="togglePopover()"
                    v-if="!range.start"
                    value="Выбрать"
                  />
                  <input
                    v-if="range.start"
                    :value="inputValue"
                    v-on="inputEvents"
                  />
                </template>
              </date-picker>
              <span v-if="range.end">по</span>
              <date-picker
                v-if="range.start"
                v-model="range.end"
                mode="date"
                :min-date="range.start"
                :max-date="new Date()"
              >
                <template v-slot="{ inputValue, inputEvents, togglePopover }">
                  <input
                    type="button"
                    @click.stop="togglePopover()"
                    v-if="!range.end"
                    value="Дата до"
                  />
                  <input
                    v-if="range.end"
                    :value="inputValue"
                    v-on="inputEvents"
                  />
                </template>
              </date-picker>
            </span>
            <label
                v-if="range.start"
                for="clear_date"
                class="logs-filter__date-clear"
                @click="clearDate"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="logs-filter__date-clear--icon"
                  enable-background="new 0 0 413.348 413.348"
                  viewBox="0 0 413.348 413.348"
                >
                  <path
                    d="m413.348 24.354-24.354-24.354-182.32 182.32-182.32-182.32-24.354 24.354 182.32 182.32-182.32 182.32 24.354 24.354 182.32-182.32 182.32 182.32 24.354-24.354-182.32-182.32z"
                  />
                </svg>
              </label>
          </div>
        </div>
        <div class="logs-filter">
          <div
            class="logs-filter__item"
            :class="{ active: logTypeId == 1 }"
            @click="getFiltredLogs(1)"
          >
            Error
          </div>
          <div
            class="logs-filter__item"
            :class="{ active: logTypeId == 2 }"
            @click="getFiltredLogs(2)"
          >
            Redirect
          </div>
          <div
            class="logs-filter__item"
            :class="{ active: logTypeId == 3 }"
            @click="getFiltredLogs(3)"
          >
            Info
          </div>
          <div
            class="logs-filter__item"
            :class="{ active: logTypeId == 4 }"
            @click="getFiltredLogs(4)"
          >
            SendEmail
          </div>
        </div>
      </div>
      <div class="log-plug" v-if="logs.length == 0">
        По данному запросу логов нет.
      </div>
      <div class="logs-wrap">
        <div class="log-item" v-for="log in logs" :key="log.Id">
          <span v-if="log.Id">
            {{ log.Id }}
          </span>
          <span v-if="log.CreateTime">
            {{ toLocalDate(log.CreateTime) }}
          </span>
          <span v-if="log.LogType">
            {{ log.LogType }}
          </span>
          <div v-if="log.Content" class="log-content">
            {{ log.Content }}
          </div>
        </div>
      </div>
      <paginate
          v-if="pages > 1"
          :page-count="pages"
          :page-range="5"
          :click-handler="onPageChange"
          :prev-text="'«'"
          :next-text="'»'"
          :container-class="'pagination'"
      >
      </paginate>
    </section>
  </div>
</template>
<script>
import Paginate from 'vuejs-paginate-next';
export default {
  name: 'Logs',
  data() {
    return {
      title_name: "Логи",
      pageNum: 1,
      logTypeId: 0,
      sortDirection: 1,
      pages: 1,
      range: {
        start: null,
        end: null,
      },
      modelConfig: {
        timeAdjust: "00:00:00",
      },
      logs: [],
      urlAPI: 'https://go.ast.ru'
    };
  },
  components:{ paginate: Paginate },
  computed: {
    dataAPI() {
      let q = {
        page: this.pageNum,
        sortDirection: this.sortDirection,
      };
      this.logTypeId != 0 ? (q["logTypeId"] = this.logTypeId) : "";
      this.range.start  ? (q["fromDate"] = this.range.start ) : "";
      this.range.end ? (q["toDate"] = this.range.end) : "";
      return q;
    },
  },
  watch: {
    async dataAPI() {
      return await this.getLogs(this.dataAPI)
    },
  },
  methods: {
    onPageChange(page) {
      this.pageNum = page;
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    getFiltredLogs(logTypeId) {
      this.logTypeId == logTypeId
        ? (this.logTypeId = 0)
        : (this.logTypeId = logTypeId);
      this.pageNum = 1;
    },
    toLocalDate(d) {
      let dd = new Date(d),
        localDate = dd.toLocaleDateString("ru-RU"),
        localTime = dd.toLocaleTimeString("ru-RU");
      return `${localTime} - ${localDate}`;
    },
    clearDate() {
      this.range.start = null;
      this.range.end = null;
    },
    getLogs(data = {}) {
      return this.$axios.post('/user/getlogs',data)
          .then((a) => {
            this.logs = a.data.Items
            this.pages = a.data.Pages
          })
          .catch((error) => {
            if (error.response.status === 401) {
              this.$store.dispatch('LOGOUT');
            }
            console.log(error);
          });
    }
  },
  mounted() {
    this.getLogs({sortDirection: this.sortDirection})
  },
};
</script>

<style scoped>
.select-setting.date {
  max-width: 100% !important;
}
.logs-page {
  flex-direction: column;
}
.logs-wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  overflow: hidden;
}
.log-item {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: auto;
  overflow: hidden;
  max-width: 100%;
  margin: 10px 1vw;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 3px 15px;
  font-size: 12px;
  color: #888;
}
.log-content {
  width: 100%;
  font-size: 12px;
  color: #333;
}
.logs-filter__wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
@media only screen and (max-width: 768px) {
  .logs-filter__wrap {
    justify-content: space-around;
  }
  .logs-filter__date-item input {
    max-width: 100px;
  }
}
.logs-filter {
  display: flex;
  padding: 0 1vw;
  margin: 8px 0;
}
.logs-filter__item {
  /* width: 10vw; */
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  padding: 3px 16px;
  margin-right: 8px;
  color: #333;
  transition: 0.3s ease;
  text-align: center;
}
.logs-filter__item:hover,
.logs-filter__item.active {
  border-color: #999;
  background-color: #d9d9d9;
  cursor: pointer;
}
.logs-filter__date {
  display: flex;
  align-items: center;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  padding-left: 16px;
  overflow: hidden;
}
.logs-filter__date-wrap {
  border-left: 1px solid #d9d9d9;
  margin-left: 16px;
  padding: 0 10px;
  display: flex;
  align-items: center;
}
.logs-filter__date-wrap input {
  max-width: 100px;
  border: 0;
  text-align: center;
  caret-color: transparent;
}
.logs-filter__date-clear {
    position: relative;
    height: 100%;
    width: 24px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #d9d9d9;
    cursor: pointer;
}
.logs-filter__date-clear--icon {
    width: 12px;
    height: 8px;
}
.log-plug {
  color: rgb(177, 177, 177);
  text-align: center;
  margin: 35px 0;
}
</style>
