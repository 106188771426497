<template>
  <div class="login_wrap">
    <div class="login">
      <form class="login_form" @submit.prevent="resetPass">
        <h2>Для продолжения работы с сервисом<br> Вам необходимо сменить пароль</h2>
        <input required v-model="email" type="email" placeholder="E-mail" class="login_input">
        <input required v-model="password" type="text" placeholder="Пароль" class="login_input"
               >
        <input type="submit" value="Сменить пароль" class="login_btn">
      </form>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
          email: '',
          password: ''
      };
    },
    methods: {
      resetPass: function () {
        let user = {"email": this.email,"password":this.password};
		   	this.$store.dispatch('RESET_PASSWORD', user)
		   	.then(() => this.$router.push('/'))
		   	.catch(err => console.log(err))
        }
      }
    };

</script>

<style>
h2 {
  text-align: center;
  font-size: 18px;
  margin: 0 auto 26px;
  line-height: 1.3em;
}
</style>
