<template>
  <div class="content-wrap">
    <header-main :title_name="title_name"></header-main>
    <section class="menu second">
      <div class="container">
        <div class="statistics-head_row">
          <div class="statistics-option_row">
            <div class="statistics-option-item select-arrow">
              Группировка периодов:
              <select
                v-model="groupBy"
                class="select-statistics"
                name="date"
                id="date"
                @change="GetChart"
              >
                <option value="0">По дням</option>
                <option value="1">По неделям</option>
                <option value="2">По месяцам</option>
              </select>
            </div>
            <div class="statistics-option-item select-arrow">
              Департамент:
              <select
                v-model="depId"
                class="select-statistics"
                name="depID"
                id="depID"
                @change="GetChart"
              >
                <option value="0">Все</option>
                <option v-for="d in DEPARTMENTS" :key="d.Key" :value="d.Key">
                  {{ d.Value }}
                </option>
              </select>
            </div>
            <div class="statistics-option-item search">
              <form>
                <input
                  type="text"
                  v-model="url"
                  class="input-statistics"
                  placeholder="Введите короткую ссылку или url без utm"
                />
                <input
                  type="submit"
                  class="button-statistics"
                  value="OK"
                  @click="GetChartURL"
                />
              </form>
            </div>
          </div>
          <div class="statistics-link_wrap">
            <a href="#" class="statistics-link" @click="GetChartCSV"
              >Выгрузить CSV</a
            >

            <a
              href="../statistics.pdf"
              class="statistics-link"
              >Инструкция</a
            >
          </div>
        </div>
      </div>
    </section>
    <div
      class="statistics-filter-title open_filter-button"
      :class="{ open_filter }"
      @click="open_filter = !open_filter"
    >
      Фильтры
    </div>
    <section class="menu third">
      <div class="container">
        <div class="statistics-filter_row" :class="{ open_filter }">
          <div class="statistics-filter-item select-arrow">
            Канал:
            <select class="select-filter" v-model="mediaId" @change="GetChart">
              <option value>Все</option>
              <option
                v-for="media in TABLE.mediaInfo"
                :key="media.id"
                :value="media.id"
              >
                {{ media.name }}
              </option>
            </select>
          </div>
          <div class="statistics-filter-item select-arrow" v-if="mediaId != 0">
            Аккаунт:
            <select
              class="select-filter"
              v-model="accountId"
              @change="GetChart"
            >
              <option value>Все</option>
              <option
                v-for="acc in ArrayOption[0].accounts"
                :key="acc.id"
                :value="acc.id"
              >
                {{ acc.data }}
              </option>
            </select>
          </div>

          <div
            class="statistics-filter-item"
            :class="{ 'select-arrow': startDate == null }"
            v-show="createDate == null"
          >
            Период:
            <date-picker
              v-model="startDate"
              mode="date"
              :max-date="new Date()"
              @change="GetChart"
              style="display: inline-block"
            >
              <template v-slot="{ inputValue, inputEvents, togglePopover }">
                <input
                  class="select-setting date"
                  type="button"
                  @click.stop="togglePopover()"
                  v-if="!startDate"
                  value="Выбрать"
                />
                <input
                  v-if="startDate"
                  class="select-setting date"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </date-picker>
            <span v-if="startDate">
              -
              <date-picker
                v-model="endDate"
                mode="date"
                :min-date="startDate"
                :max-date="new Date()"
                @change="GetChart"
                style="display: inline-block"
              >
                <template v-slot="{ inputValue, inputEvents, togglePopover }">
                  <input
                    class="select-setting date"
                    type="button"
                    @click.stop="togglePopover()"
                    v-if="!endDate"
                    value="Дата до"
                  />
                  <input
                    v-if="endDate"
                    class="select-setting date"
                    :value="inputValue"
                    v-on="inputEvents"
                  />
                </template>
              </date-picker>
              <label
                for="clear_date"
                class="clear-date_label"
                @click="clearDate"
              >
                <input type="button" id="clear_date" class="clear-date_input" />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="clear-date_icon"
                  enable-background="new 0 0 413.348 413.348"
                  height="512"
                  viewBox="0 0 413.348 413.348"
                  width="512"
                >
                  <path
                    d="m413.348 24.354-24.354-24.354-182.32 182.32-182.32-182.32-24.354 24.354 182.32 182.32-182.32 182.32 24.354 24.354 182.32-182.32 182.32 182.32 24.354-24.354-182.32-182.32z"
                  />
                </svg>
              </label>
            </span>
          </div>
          <div
            class="statistics-filter-item"
            :class="{ 'select-arrow': createDate === null }"
            v-show="startDate == null"
          >
            Дата создания:
            <date-picker
              v-model="createDate"
              mode="date"
              :max-date="new Date()"
              @change="GetChart"
              style="display: inline-block"
            >
              <template v-slot="{ inputValue, inputEvents, togglePopover }">
                <input
                  class="select-setting date"
                  type="button"
                  @click.stop="togglePopover()"
                  v-if="!createDate"
                  value="Выбрать"
                />
                <input
                  v-if="createDate"
                  class="select-setting date"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </date-picker>
            <label
              v-if="createDate != null "
              class="clear-date_label"
              @click="clearCreateDate"
            >
              <input
                type="button"
                class="clear-date_input"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="clear-date_icon"
                enable-background="new 0 0 413.348 413.348"
                height="512"
                viewBox="0 0 413.348 413.348"
                width="512"
              >
                <path
                  d="m413.348 24.354-24.354-24.354-182.32 182.32-182.32-182.32-24.354 24.354 182.32 182.32-182.32 182.32 24.354 24.354 182.32-182.32 182.32 182.32 24.354-24.354-182.32-182.32z"
                />
              </svg>
            </label>
          </div>
          <div class="statistics-filter-item select-arrow">
            Домен:
            <select class="select-filter" v-model="domainId" @change="GetChart">
              <option value>Все</option>
              <option v-for="(i, d) in OPTIONS.domains" :key="i" :value="i">
                {{ d }}
              </option>
            </select>
          </div>
          <div class="statistics-filter-item select-arrow" v-if="mediaId != 0">
            Место размещения:
            <select
              class="select-filter"
              v-model="placmentId"
              @change="GetChart"
            >
              <option value>Все</option>
              <option
                v-for="p in ArrayOption[0].placments"
                :key="p.id"
                :value="p.id"
              >
                {{ p.data }}
              </option>
            </select>
          </div>
          <div class="statistics-filter-item select-arrow">
            Тема контента:
            <select class="select-filter" v-model="tagId" @change="GetChart">
              <option value>Все</option>
              <option v-for="(i, t) in OPTIONS.tags" :key="i" :value="i">
                {{ t }}
              </option>
            </select>
          </div>
          <div class="statistics-filter-item select-arrow">
            ID публикации:
            <select
              class="select-filter"
              v-model="contentId"
              @change="GetChart"
            >
              <option value>Все</option>
              <option
                v-for="(d, i) in OPTIONS.contentPlanId"
                :key="i"
                :value="i"
              >
                {{ d }}
              </option>
            </select>
          </div>
          <div class="statistics-filter-item select-arrow">
            Кто создал:
            <select class="select-filter" v-model="userId" @change="GetChart">
              <option value>Все</option>
              <option v-for="(i, u) in OPTIONS.users" :key="i" :value="i">
                {{ u }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </section>
    <section class="content content-wrapper statistics-content">
      <div class="sidebar-statistics">
        <div class="sidebar_wrapper">
          <div class="sidebar-statistics-head">
            <div class="sidebar-statistics-col">№</div>
            <div class="sidebar-statistics-col">Дата</div>
            <div class="sidebar-statistics-col">Кол-во переходов</div>
          </div>
          <div class="sidebar-statistics-item" v-for="(c, i) in chart" :key="i">
            <div class="sidebar-statistics-col">{{ i + 1 }}</div>
            <div class="sidebar-statistics-col">
              {{
                new Intl.DateTimeFormat("ru-Ru").format(new Date(c.timeStamp))
              }}
            </div>
            <div class="sidebar-statistics-col">{{ c.data }}</div>
          </div>
        </div>
      </div>
      <div class="content-box">
        <div class="chart">
          <Line v-if="loaded" :data="chartDate" :options="optionsChart" />
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { Line } from "vue-chartjs";
import 'chart.js/auto'
import { saveAs } from "file-saver";

export default {
  data() {
    return {
      groupBy: 0,
      depId: 0,
      mediaId: "",
      accountId: "",
      domainId: "",
      placmentId: "",
      tagId: "",
      contentId: "",
      userId: "",
      chartDate: {},
      startDate: null,
      endDate: null,
      createDate: null,
      url: "",
      title_name: "Статистика",
      open_filter: false,
      loaded: false,
      optionsChart: {
        responsive: true,
        maintainAspectRatio: false,
        title: {
          display: false,
        }
      },
      chart: ''
    };
  },
  components: { Line },
  computed: {
    TABLE() { return this.$store.getters.TABLE },
    DEPARTMENTS() { return this.$store.getters.DEPARTMENTS },
    OPTIONS() { return this.$store.getters.OPTIONS },
    ArrayOption() {
      return this.TABLE.mediaInfo.filter((option) => {
        return this.mediaId == "" || option.id === this.mediaId;
      });
    },
  },
  methods: {
    ChartOptions() {
      return new Promise((resolve) => {
        let points = [], timeStamp = [];
        this.chart.forEach((el) => {
          points.push(el.data)
          timeStamp.push(new Intl.DateTimeFormat("ru-Ru").format(new Date(el.timeStamp)))
        })
        this.chartDate = {
          labels: timeStamp,
          datasets: [
            {
              label: "Количество переходов",
              data: points,
              backgroundColor: "transparent",
              borderColor: "rgba(0,0,0,.7)",
              pointBackgroundColor: "rgba(0, 0, 0, 1)",
              tension: 0.2
            },
          ],
        }
        resolve()
      });
    },
    createDateAPI() {
      let d = {};
      if (this.url != "") {
        d.url = this.url;
      } else {
        this.depId != 0 ? d.departmentId = +this.depId : '';
        this.groupBy != 0 ? d.groupBy = +this.groupBy : '';
        this.accountId != "" ? d.accountId = +this.accountId : '';
        this.mediaId != "" ? d.mediaId = +this.mediaId : '';
        this.placmentId != "" ? d.placmentId = +this.placmentId : '';
        this.tagId != "" ? d.tagId = +this.tagId : '';
        this.userId != "" ? d.userId = +this.userId : '';
        this.domainId != "" ? d.domainId = +this.domainId : '';
        this.contentId != "" ? d.contentId = +this.contentId : '';
        this.createDate != null ? d.create = this.fdate(this.createDate) : '';
        this.startDate != null ? d.start = this.fdate(this.startDate) : '';
        this.endDate != null ? d.finish = this.fdate(this.endDate) : '';
      }
      return d;
    },
    GetChart() {
      this.createChart(this.createDateAPI())
          .then(() => this.ChartOptions().then(() => this.loaded = true))
    },
    GetChartCSV() {
      this.$axios.post('/User/CreateCsvFileByProcedure', this.createDateAPI())
          .then((chart) => {
            let dd = new Date();
            let reportName = "report " + dd.toJSON() + ".csv";
            let file = new Blob([chart.data], {
              type: "text/csv;charset=ansi",
            });
            saveAs(file, reportName);
          })
          .catch((error) => {
            if (error.response.status === 401) {
              this.$store.dispatch('LOGOUT');
            }
            console.log(error);
          });
    },
    GetChartURL(e) {
      e.preventDefault();
      this.createChart({ url: this.url } || {})
          .then(() => this.ChartOptions().then(() => this.loaded = true))
    },
    createChart(data) {
      return this.$axios.post('/user/createChart', data)
          .then(res => this.chart = res.data.chart)
          .catch(error => {
            if (error.response.status === 401) this.$store.dispatch('LOGOUT')
            console.log(error);
          });
    },
    clearDate() {
      this.startDate = "";
      this.endDate = "";
    },
    clearCreateDate() { this.createDate = null },
    fdate(d) {
      let fd = d.setHours(d.getHours() + 3);
      let fdd = new Date(fd);
      return fdd.toJSON();
    },
  },
  mounted() {
    this.$store.dispatch('GET_DEPARTMENTS')
    this.GetChart();
    this.$store.dispatch('GET_TABLE', +this.depId)
    this.$store.dispatch('GET_OPTIONS', +this.depId)
  },
  watch: {
    depId() {
      if (this.depId != 0) {
        this.$store.dispatch('GET_TABLE')
        this.$store.dispatch('GET_OPTIONS')
      }
      this.mediaId = ""
      this.accountId = ""
      this.placmentId = ""
    },
    startDate() {
      this.GetChart();
      this.endDate = "";
    },
    endDate() { this.GetChart() },
    createDate() { this.GetChart() }
  },
};
</script>

<style></style>