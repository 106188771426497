<template>
  <div class="content-wrap">
    <header-main :title_name='title_name'></header-main>
    <section class="content content-wrapper">
      <div class="container">
			<section class="section_wrapper">
				<div class="section_title">Настройки профиля</div>
        <div class="row">
          <div v-if="edit_form != 'name'" class="manager row-item profile-edit" @click="edit_form = 'name'">
            {{name}}
            <svg class="profile-edit_svg" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    		      <path d="M10.764 1.9235L10.076 1.2355C9.50747 0.6665 8.58097 0.6665 8.01197 1.2355L1.82097 7.427C1.78197 7.466 1.75347 7.514 1.73847 7.567L0.821467 10.7775C0.788967 10.891 0.820967 11.0125 0.903967 11.096C0.965967 11.158 1.04847 11.191 1.13347 11.191C1.16347 11.191 1.19347 11.187 1.22247 11.1785L4.43297 10.261C4.48597 10.246 4.53447 10.2175 4.57347 10.1785L10.764 3.9875C11.04 3.712 11.1915 3.3455 11.1915 2.9555C11.1915 2.5655 11.04 2.199 10.764 1.9235ZM4.17347 9.661L1.60497 10.3945L2.33897 7.8265L7.32447 2.841L9.15897 4.6755L4.17347 9.661ZM10.3055 3.529L9.61747 4.217L7.78297 2.3825L8.47097 1.6945C8.78697 1.3785 9.30147 1.379 9.61797 1.6945L10.306 2.3825C10.4595 2.5355 10.5435 2.7395 10.5435 2.956C10.543 3.172 10.4585 3.3755 10.3055 3.529Z" fill="#0038FF"/>
          	</svg>
          </div>
          <div v-if="edit_form == 'name'" class="manager row-item">
            <input class="profile-edit_input" v-model="name">
            <input class="profile-edit_input" type="submit" @click="EditUser" value="OK">
          </div>
          <a v-if="edit_form != 'email'" class="manager-email row-item profile-edit" @click="edit_form = 'email'">
            {{email}}
            <svg class="profile-edit_svg" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    		      <path d="M10.764 1.9235L10.076 1.2355C9.50747 0.6665 8.58097 0.6665 8.01197 1.2355L1.82097 7.427C1.78197 7.466 1.75347 7.514 1.73847 7.567L0.821467 10.7775C0.788967 10.891 0.820967 11.0125 0.903967 11.096C0.965967 11.158 1.04847 11.191 1.13347 11.191C1.16347 11.191 1.19347 11.187 1.22247 11.1785L4.43297 10.261C4.48597 10.246 4.53447 10.2175 4.57347 10.1785L10.764 3.9875C11.04 3.712 11.1915 3.3455 11.1915 2.9555C11.1915 2.5655 11.04 2.199 10.764 1.9235ZM4.17347 9.661L1.60497 10.3945L2.33897 7.8265L7.32447 2.841L9.15897 4.6755L4.17347 9.661ZM10.3055 3.529L9.61747 4.217L7.78297 2.3825L8.47097 1.6945C8.78697 1.3785 9.30147 1.379 9.61797 1.6945L10.306 2.3825C10.4595 2.5355 10.5435 2.7395 10.5435 2.956C10.543 3.172 10.4585 3.3755 10.3055 3.529Z" fill="#0038FF"/>
          	</svg>
          </a>
          <div v-if="edit_form == 'email'" class="manager-email row-item profile-edit">
            <input class="profile-edit_input" v-model="email">
            <input class="profile-edit_input" type="submit" @click="EditUser" value="OK">
          </div>
          <div class="manager row-item user-admin">
            <span v-if="DEPARTMENTS[2] != undefined" class="user-admin"> Admin</span>
            <span v-if="DEPARTMENTS[2] == undefined" class="user-admin"> Менеджер SMM</span>
          </div>
        </div>
        <div class="row">
					<a @click="showEdit = true">Установить новый пароль</a>
				</div>
        <div class="row">
					<a @click="logout">Выйти</a>
				</div>
			</section>
		</div>
    <new-password v-if="showEdit" @close="showEdit = false"></new-password>
    </section>
  </div>
</template>
<script>
import NewPassword from '@/components/NewPassword'
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      edit_form: '',
      name: '',
      email: '',
      showEdit: false,
      title_name: 'Профиль'
    };
  },
  components: {
    NewPassword
  },
  computed: {
    ...mapGetters([
        'USER',
        'DEPARTMENTS'
    ])
  },
  methods: {
    ...mapActions(["EDIT_CREDENTIALS"]),
    logout: function () {
        this.$store.dispatch('LOGOUT')
        .then(() => {
          this.$router.push('/adm/login')
        })
      },
    EditUser: function() {
      if (this.edit_form == 'email') {
        var r = {
        "email": this.email
        }
        this.$store.commit('SET_USER_EMAIL', r)
      }
      if (this.edit_form == 'name') {
        var r = {
        "name": this.name
        }
        this.$store.commit('SET_USER_NAME', r)
      }
      this.EDIT_CREDENTIALS(r)
       .then( () => {
         this.USER
         this.edit_form = ''
       })
    }
  },
  mounted() {
    this.USER
    this.name = this.USER.name
    this.email = this.USER.email
  }
};
</script>

