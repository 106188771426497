<template>
  <div>
    <header class="header">
      <div class="container header-wrapper">
        <div class="breadcrumbs">
           <router-link to="/" class="menu-link">go.ast.ru</router-link>
           <a v-if="DEPARTMENT_SELECT != ''">{{title_name}}</a>
           <a v-if="SOURCESELECT.account.data != ''"> {{SOURCESELECT.account.data}} </a>
        </div>
        <span v-if="isLoggedIn"><router-link to="/adm/profile"> {{USER.name}}</router-link> | <a @click="logout">Выйти</a></span>
      </div>
    </header>
    <section class="menu">
		<div class="container">
      <router-link to="/" class="menu-link">Ссылки</router-link>
			<router-link to="/adm/statistics" class="menu-link">Статистика</router-link>
      <a href="https://infogo.ast.ru/" target="_blank" class="menu-link">Инструкция</a>
      <router-link v-if='Admin == "true"' to="/adm/settings" class="menu-link">Настройки</router-link>
      <router-link v-if='Admin == "true"' to="/adm/logs" class="menu-link">Логи</router-link>
		</div>
	</section>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    data() {
      return {
        path: '',
        Admin: localStorage.getItem('isAdmin') || false,
      }
    },
    computed : {
      ...mapGetters([
        'USER',
        'SOURCESELECT',
        'DEPARTMENTS',
        'QUANTITY_DEP',
        'DEPARTMENT_SELECT'
        ]),
      isLoggedIn : function(){ return this.$store.getters.isLoggedIn},
      title_name() {
        return (this.DEPARTMENT_SELECT == '' ? '' : this.DEPARTMENTS[this.DEPARTMENT_SELECT].Value)
      }
    },
    methods: {
      logout: function () {
        this.$store.dispatch('LOGOUT')
        .then(() => {
          this.$router.push('/adm/login')
        })
      },
    },
    mounted() {
      this.USER
      this.path = this.$route.path
      this.$store.dispatch('GET_DEPARTMENTS')
          .catch(() => this.$router.push('/adm/login'));
    }
  }
</script>