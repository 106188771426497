<template>
  <transition>
    <div>
      <div class="modal">
        <svg
          class="modal__cross"
          @click="$emit('close')"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path
            d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"
          />
        </svg>
        <div class="modal__wrapper">
          <div class="add-shortlink_title">
            <span>Создание нового Медиа</span>
          </div>
          <div class="modal__content">
            <form @submit.prevent="addMedia" class="form100">
              <input
                required
                type="text"
                v-model="name"
                class="add-shortlink_url add-shortlink_input"
                placeholder="Наименование Медиа"
              />
              <input
                required
                type="text"
                v-model="symbol"
                class="add-shortlink_url add-shortlink_input"
                placeholder="Символ Медиа"
              />
              <div class="shortlink_done_btn">
                <input type="submit" value="OK" />
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="overlay overlay-modal" @click="$emit('close')"></div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      name: '',
      symbol: '',
    };
  },
  methods: {
    addMedia() {
      let data = {
        newMedia: this.name,
        newSymbol: this.symbol,
      };
      this.$axios.post('/admin/addmedia', data)
          .then(() => this.$emit("close"));
    },
  },
};
</script>
