<template>
  <transition>
    <div>
      <div class="modal">
        <svg class="modal__cross" @click="$emit('close')" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z" /></svg>
        <div class="add-shortlink_title">
            <span>Установите новый пароль</span>
        </div>
        <form @submit.prevent="editPass" class="form100">
          <input
            required
            type="password"
            v-model="pass"
            class="add-shortlink_url add-shortlink_input"
            placeholder="Введите новый пароль"
          />
          <input 
            required
            type="password"
            v-model="pass2"
            class="add-shortlink_url add-shortlink_input"
            placeholder="Повторите новый пароль"
          />
          <div class="shortlink_done_btn">
            <div v-if="incorrect" class="incorrect"> Пароли не совподают!</div>
            <input type="submit" value="OK">
          </div>
        </form>
      </div>
      <div class="overlay overlay-modal" @click="$emit('close')"></div>
    </div>
  </transition>
</template>

<script>
import {mapActions} from "vuex";
// import Vue from 'vue'
// import VueClipboard from 'vue-clipboard2'
//
// Vue.use(VueClipboard)

export default {
	data() {
		return {
        pass: '',
        pass2: '',
        incorrect: false,
        adminstatus: true,
			};
  },
	methods: {
    ...mapActions(['EDIT_CREDENTIALS']),
		editPass () {
      if (this.pass == this.pass2) {
        let d = {
          "password": this.pass
        }
        this.EDIT_CREDENTIALS(d)
          .then(() => this.$emit('close'))
      } else {
        this.incorrect = true
      }
    }
  }
};
</script>
