<template>
    <div class="shortlink-row">
    	<div class="shortlink-short"><a :href="link_data.shortenedUrl" target="blank" class="shortlink-short-link" style="margin-left: 0px">{{link_data.shortenedUrl}}</a>
			<a v-clipboard:copy="link_data.shortenedUrl" ><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    		<path d="M8.62501 4.5H7.12501C6.82664 4.5 6.54049 4.38147 6.32951 4.17049C6.11853 3.95952 6.00001 3.67337 6.00001 3.375V1.875C5.99957 1.80051 6.02133 1.72759 6.06251 1.66552C6.10368 1.60345 6.16241 1.55505 6.2312 1.52648C6.29999 1.49792 6.37573 1.49049 6.44876 1.50514C6.52179 1.5198 6.5888 1.55586 6.64126 1.60875L8.89126 3.85875C8.94414 3.9112 8.98021 3.97821 8.99486 4.05124C9.00951 4.12427 9.00208 4.20001 8.97352 4.26881C8.94496 4.3376 8.89656 4.39632 8.83449 4.4375C8.77242 4.47867 8.69949 4.50043 8.62501 4.5ZM6.75001 2.77875V3.375C6.75001 3.47446 6.78952 3.56984 6.85984 3.64016C6.93017 3.71049 7.02555 3.75 7.12501 3.75H7.72126L6.75001 2.77875Z" fill="#0038FF"/>
    		<path d="M7.875 12H1.875C1.57663 12 1.29048 11.8815 1.0795 11.6705C0.868526 11.4595 0.75 11.1734 0.75 10.875V2.625C0.75 2.32663 0.868526 2.04048 1.0795 1.8295C1.29048 1.61853 1.57663 1.5 1.875 1.5H6.375C6.42435 1.49971 6.47328 1.50917 6.51897 1.52783C6.56465 1.5465 6.60621 1.57399 6.64125 1.60875L8.89125 3.85875C8.92601 3.89379 8.9535 3.93534 8.97216 3.98103C8.99083 4.02672 9.00029 4.07565 9 4.125V10.875C9 11.1734 8.88147 11.4595 8.6705 11.6705C8.45952 11.8815 8.17337 12 7.875 12ZM1.875 2.25C1.77554 2.25 1.68016 2.28951 1.60984 2.35983C1.53951 2.43016 1.5 2.52554 1.5 2.625V10.875C1.5 10.9745 1.53951 11.0698 1.60984 11.1402C1.68016 11.2105 1.77554 11.25 1.875 11.25H7.875C7.97446 11.25 8.06984 11.2105 8.14017 11.1402C8.21049 11.0698 8.25 10.9745 8.25 10.875V4.27875L6.22125 2.25H1.875Z" fill="#0038FF"/>
    		<path d="M10.875 3H9.37501C9.07664 3 8.79049 2.88147 8.57951 2.67049C8.36853 2.45952 8.25001 2.17337 8.25001 1.875V0.374999C8.24957 0.300515 8.27133 0.227589 8.31251 0.165519C8.35368 0.103449 8.41241 0.0550474 8.4812 0.0264848C8.54999 -0.00207772 8.62573 -0.00950702 8.69876 0.0051441C8.77179 0.0197952 8.8388 0.055863 8.89126 0.108749L11.1413 2.35875C11.1941 2.4112 11.2302 2.47821 11.2449 2.55124C11.2595 2.62427 11.2521 2.70001 11.2235 2.7688C11.195 2.8376 11.1466 2.89632 11.0845 2.9375C11.0224 2.97867 10.9495 3.00043 10.875 3ZM9.00001 1.27875V1.875C9.00001 1.97446 9.03952 2.06984 9.10984 2.14016C9.18017 2.21049 9.27555 2.25 9.37501 2.25H9.97126L9.00001 1.27875Z" fill="#0038FF"/>
    		<path d="M10.125 10.5H8.625C8.52554 10.5 8.43016 10.4605 8.35983 10.3902C8.28951 10.3198 8.25 10.2245 8.25 10.125C8.25 10.0255 8.28951 9.93016 8.35983 9.85983C8.43016 9.78951 8.52554 9.75 8.625 9.75H10.125C10.2245 9.75 10.3198 9.71049 10.3902 9.64016C10.4605 9.56984 10.5 9.47445 10.5 9.375V2.77875L8.47125 0.749999H4.125C4.02554 0.749999 3.93016 0.789507 3.85984 0.859833C3.78951 0.93016 3.75 1.02554 3.75 1.125V1.875C3.75 1.97445 3.71049 2.06984 3.64016 2.14016C3.56984 2.21049 3.47446 2.25 3.375 2.25C3.27554 2.25 3.18016 2.21049 3.10984 2.14016C3.03951 2.06984 3 1.97445 3 1.875V1.125C3 0.82663 3.11853 0.540482 3.3295 0.329503C3.54048 0.118525 3.82663 -1.36154e-06 4.125 -1.36154e-06H8.625C8.67435 -0.000286696 8.72328 0.0091727 8.76897 0.0278344C8.81465 0.0464961 8.85621 0.0739931 8.89125 0.108749L11.1412 2.35875C11.176 2.39379 11.2035 2.43534 11.2222 2.48103C11.2408 2.52672 11.2503 2.57565 11.25 2.625V9.375C11.25 9.67337 11.1315 9.95951 10.9205 10.1705C10.7095 10.3815 10.4234 10.5 10.125 10.5Z" fill="#0038FF"/>
    		</svg></a> </div>
		<div class="shortlink-url__wrapper">
			<a :href="link_data.utm" target="blank" class="shortlink-url" :title="link_data.utm">{{link_data.utm}}</a>
			<a v-clipboard:copy="link_data.utm"><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    		<path d="M8.62501 4.5H7.12501C6.82664 4.5 6.54049 4.38147 6.32951 4.17049C6.11853 3.95952 6.00001 3.67337 6.00001 3.375V1.875C5.99957 1.80051 6.02133 1.72759 6.06251 1.66552C6.10368 1.60345 6.16241 1.55505 6.2312 1.52648C6.29999 1.49792 6.37573 1.49049 6.44876 1.50514C6.52179 1.5198 6.5888 1.55586 6.64126 1.60875L8.89126 3.85875C8.94414 3.9112 8.98021 3.97821 8.99486 4.05124C9.00951 4.12427 9.00208 4.20001 8.97352 4.26881C8.94496 4.3376 8.89656 4.39632 8.83449 4.4375C8.77242 4.47867 8.69949 4.50043 8.62501 4.5ZM6.75001 2.77875V3.375C6.75001 3.47446 6.78952 3.56984 6.85984 3.64016C6.93017 3.71049 7.02555 3.75 7.12501 3.75H7.72126L6.75001 2.77875Z" fill="#0038FF"/>
    		<path d="M7.875 12H1.875C1.57663 12 1.29048 11.8815 1.0795 11.6705C0.868526 11.4595 0.75 11.1734 0.75 10.875V2.625C0.75 2.32663 0.868526 2.04048 1.0795 1.8295C1.29048 1.61853 1.57663 1.5 1.875 1.5H6.375C6.42435 1.49971 6.47328 1.50917 6.51897 1.52783C6.56465 1.5465 6.60621 1.57399 6.64125 1.60875L8.89125 3.85875C8.92601 3.89379 8.9535 3.93534 8.97216 3.98103C8.99083 4.02672 9.00029 4.07565 9 4.125V10.875C9 11.1734 8.88147 11.4595 8.6705 11.6705C8.45952 11.8815 8.17337 12 7.875 12ZM1.875 2.25C1.77554 2.25 1.68016 2.28951 1.60984 2.35983C1.53951 2.43016 1.5 2.52554 1.5 2.625V10.875C1.5 10.9745 1.53951 11.0698 1.60984 11.1402C1.68016 11.2105 1.77554 11.25 1.875 11.25H7.875C7.97446 11.25 8.06984 11.2105 8.14017 11.1402C8.21049 11.0698 8.25 10.9745 8.25 10.875V4.27875L6.22125 2.25H1.875Z" fill="#0038FF"/>
    		<path d="M10.875 3H9.37501C9.07664 3 8.79049 2.88147 8.57951 2.67049C8.36853 2.45952 8.25001 2.17337 8.25001 1.875V0.374999C8.24957 0.300515 8.27133 0.227589 8.31251 0.165519C8.35368 0.103449 8.41241 0.0550474 8.4812 0.0264848C8.54999 -0.00207772 8.62573 -0.00950702 8.69876 0.0051441C8.77179 0.0197952 8.8388 0.055863 8.89126 0.108749L11.1413 2.35875C11.1941 2.4112 11.2302 2.47821 11.2449 2.55124C11.2595 2.62427 11.2521 2.70001 11.2235 2.7688C11.195 2.8376 11.1466 2.89632 11.0845 2.9375C11.0224 2.97867 10.9495 3.00043 10.875 3ZM9.00001 1.27875V1.875C9.00001 1.97446 9.03952 2.06984 9.10984 2.14016C9.18017 2.21049 9.27555 2.25 9.37501 2.25H9.97126L9.00001 1.27875Z" fill="#0038FF"/>
    		<path d="M10.125 10.5H8.625C8.52554 10.5 8.43016 10.4605 8.35983 10.3902C8.28951 10.3198 8.25 10.2245 8.25 10.125C8.25 10.0255 8.28951 9.93016 8.35983 9.85983C8.43016 9.78951 8.52554 9.75 8.625 9.75H10.125C10.2245 9.75 10.3198 9.71049 10.3902 9.64016C10.4605 9.56984 10.5 9.47445 10.5 9.375V2.77875L8.47125 0.749999H4.125C4.02554 0.749999 3.93016 0.789507 3.85984 0.859833C3.78951 0.93016 3.75 1.02554 3.75 1.125V1.875C3.75 1.97445 3.71049 2.06984 3.64016 2.14016C3.56984 2.21049 3.47446 2.25 3.375 2.25C3.27554 2.25 3.18016 2.21049 3.10984 2.14016C3.03951 2.06984 3 1.97445 3 1.875V1.125C3 0.82663 3.11853 0.540482 3.3295 0.329503C3.54048 0.118525 3.82663 -1.36154e-06 4.125 -1.36154e-06H8.625C8.67435 -0.000286696 8.72328 0.0091727 8.76897 0.0278344C8.81465 0.0464961 8.85621 0.0739931 8.89125 0.108749L11.1412 2.35875C11.176 2.39379 11.2035 2.43534 11.2222 2.48103C11.2408 2.52672 11.2503 2.57565 11.25 2.625V9.375C11.25 9.67337 11.1315 9.95951 10.9205 10.1705C10.7095 10.3815 10.4234 10.5 10.125 10.5Z" fill="#0038FF"/>
    		</svg></a>
		</div>
    	<div class="shortlink-url__wrapper tablet-mobile-hide">
			<a :href="link_data.url" target="blank" class="shortlink-url" :title="link_data.url">{{link_data.url}}</a>
		</div>
    	<div class="shortlink-space">{{link_data.place.data}} </div>
    	<div class="shortlink-hashtag" :title="link_data.tag.data">{{link_data.tag.data}} </div>
    	<div class="shortlink-id">ID {{link_data.publicationId}} </div>
    	<div class="shortlink-activites">{{link_data.redirectCount}} </div>
	</div>

</template>

<script>
export default {
    props: {
        link_data: {
            type: Object,
            default() {
                return{}
            }
		},
	}
}
</script>

