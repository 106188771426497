<template>
  <div class="content-wrap">
    <header-main></header-main>
    <section class="menu second">
      <div class="container">
        <transition name="fade">
          <button
            class="menu-link open-modal"
            @click="openModal((showCreate = true), openModal())"
            :oq="OQ"
            v-if="DEPARTMENT_SELECT"
          >
            Сократить ссылку
          </button>
        </transition>
        <transition name="fade">
          <button
            class="menu-link open-modal"
            v-if="DEPARTMENT_SELECT && !singleAcc && QUANTITY_DEP !== 1"
            @click="clear_select()"
          >
            Показать все ссылки
          </button>
        </transition>
        <transition name="fade">
          <span class="menu-link" v-if="!DEPARTMENT_SELECT">
            Для сокращения ссылки необходимо выбрать департамент
          </span>
        </transition>
        <transition name="fade">
          <button
            class="menu-link open-modal"
            v-if="searchComplete"
            @click="getNewLink()"
          >
            Очистить поиск
          </button>
        </transition>
      </div>
    </section>
    <section class="content content-wrapper department-content">
      <div
        class="sidebar_open-button"
        @click="(sidebar_open = !sidebar_open), openModal()"
      >
        Выбрать источник
      </div>
      <sidebar
        :sidebar_open="sidebar_open"
        @close="(sidebar_open = false), closeModal()"
        @clearFilter="placeFiltered = ''"
      ></sidebar>
      <div class="content-box">
        <div
          class="links_settings-title open_filter-button"
          :class="{ open_filter }"
          @click="open_filter = !open_filter"
        >
          Фильтры
        </div>
        <div class="links_settings" :class="{ open_filter }">
          <div class="links_settings-item">
            Дата создания:
            <date-picker
              v-model="startDate"
              mode="date"
              :max-date="new Date()"
              style="display: inline-block"
            >
              <template v-slot="{ inputValue, inputEvents, togglePopover }">
                <input
                  class="select-setting date"
                  type="button"
                  @click.stop="togglePopover()"
                  v-if="!startDate"
                  value="Выбрать"
                />
                <input
                  v-if="startDate"
                  class="select-setting date"
                  :value="inputValue"
                  v-on="inputEvents"
                />
              </template>
            </date-picker>
            <span v-if="startDate">
              -
              <date-picker
                v-model="endDate"
                mode="date"
                :min-date="startDate"
                :max-date="new Date()"
                style="display: inline-block"
              >
                <template v-slot="{ inputValue, inputEvents, togglePopover }">
                  <input
                    class="select-setting date"
                    type="button"
                    @click.stop="togglePopover()"
                    v-if="!endDate"
                    value="Дата до"
                  />
                  <input
                    v-if="endDate"
                    class="select-setting date"
                    :value="inputValue"
                    v-on="inputEvents"
                  />
                </template>
              </date-picker>
              <label
                for="clear_date"
                class="clear-date_label"
                @click="clearDate"
              >
                <input type="button" id="clear_date" class="clear-date_input" />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="clear-date_icon"
                  enable-background="new 0 0 413.348 413.348"
                  height="512"
                  viewBox="0 0 413.348 413.348"
                  width="512"
                >
                  <path
                    d="m413.348 24.354-24.354-24.354-182.32 182.32-182.32-182.32-24.354 24.354 182.32 182.32-182.32 182.32 24.354 24.354 182.32-182.32 182.32 182.32 24.354-24.354-182.32-182.32z"
                  />
                </svg>
              </label>
            </span>
          </div>
          <div class="links_settings-item select-arrow">
            Домен:
            <select
              id="domain"
              v-model="domainFiltered"
              class="select-setting"
            >
              <option value="">Все</option>
              <option
                v-for="(i, domain) in OPTIONS.domains"
                :key="i"
                :value="i"
              >
                {{ domain }}
              </option>
            </select>
          </div>
          <transition name="fade">
            <div
              class="links_settings-item select-arrow"
              v-if="SOURCESELECT.media.id !== '' && FilteredOption.length !== 0"
            >
              Место размещения:
              <select
                id="space"
                v-model="placeFiltered"
                class="select-setting"
              >
                <option value="" :selected="placeFiltered === ''">Все</option>
                <option
                  v-for="place in FilteredOption[0].placments"
                  :key="place.id"
                  :value="place.id"
                >
                  {{ place.data }}
                </option>
              </select>
            </div>
          </transition>
          <div class="links_settings-item select-arrow">
            Тема контента:
            <select
              id="hashtag"
              v-model="tagFiltered"
              class="select-setting"
            >
              <option value="">Все</option>
              <option v-for="(i, tag) in OPTIONS.tags" :key="i" :value="i">
                {{ tag }}
              </option>
            </select>
          </div>
          <div class="links_settings-item">
            ID:
            <input
              type="text"
              name="PubID"
              class="input-setting"
              v-model="idPublication"
            />
          </div>
          <div class="links_settings-item select-arrow">
            Кто создал:
            <select
              id="author"
              v-model="userFiltered"
              class="select-setting"
            >
              <option :value="null">Все</option>
              <option v-for="(i, user) in OPTIONS.users" :key="i" :value="i">
                {{ user }}
              </option>
            </select>
          </div>
          <form
            class="search-box"
            @submit.prevent="searchLink()"
            :class="{ open_search }"
            @click="open_search = true"
          >
            <input
              type="search"
              v-model="searchQuery"
              class="search-box__input"
            />
            <button type="submit" class="search-box__submit"></button>
          </form>
        </div>
        <div class="shortlinks">
          <div class="shortlink-row__head">
            <div class="shortlink-short">Короткая ссылка</div>
            <div class="shortlink-url__wrapper">Размеченая ссылка</div>
            <div class="shortlink-url__wrapper tablet-mobile-hide">
              Исходная ссылка
            </div>
            <div class="shortlink-space">Место размещенеия</div>
            <div class="shortlink-hashtag">Тема контента</div>
            <div class="shortlink-id">ID</div>
            <div class="shortlink-activites"></div>
          </div>
          <shortlink-row
            v-for="(link, index) in links"
            :link_data="link"
            :link_key="index"
            :key="index"
          ></shortlink-row>
        </div>
        <paginate
            v-if="pages > 1"
            :page-count="pages"
            :page-range="5"
            :click-handler="onPageChange"
            :prev-text="'«'"
            :next-text="'»'"
            :container-class="'pagination'"
        >
        </paginate>
        <paginate
            v-if="searchPages > 1"
            :page-count="searchPages"
            :page-range="5"
            :click-handler="onSearchPageChange"
            :prev-text="'«'"
            :next-text="'»'"
            :container-class="'pagination'"
        >
        </paginate>
      </div>
    </section>
    <create-link
      v-if="showCreate"
      @close="(showCreate = false), closeModal()"
      @showNotification="showNotification"
      :singleAcc="singleAcc"
    ></create-link>
    <notification
      :activeNotification="activeNotification"
      :notificationInfo="notificationInfo"
      :notificationStatus="notificationStatus"
    />
    <transition name="show">
      <div
        class="overlay overlay-sidebar"
        @click="(sidebar_open = false), closeModal()"
        v-show="sidebar_open"
      ></div>
    </transition>
    <transition name="show">
      <div
        class="overlay overlay-sidebar"
        @click="open_search = false"
        v-show="open_search"
      ></div>
    </transition>
  </div>
</template>
<script>
import Paginate from 'vuejs-paginate-next';
import ShortlinkRow from "@/components/ShortlinkRow";
import CreateLink from "@/components/CreateLink";
import Notification from "@/components/Notification";
import { mapGetters } from "vuex";
import Sidebar from "@/components/Sidebar";

export default {
  data() {
    return {
      range: "",
      endDate: "",
      startDate: "",
      idPublication: "",
      showCreate: false,
      activeNotification: false,
      dateFiltered: "",
      domainFiltered: "",
      tagFiltered: "",
      placeFiltered: "",
      userFiltered: null,
      sortedLinks: [],
      notificationInfo: {},
      notificationStatus: 0,
      open_filter: false,
      dep: "",
      pageNum: 1,
      perPage: 25,
      singleAcc: false,
      sidebar_open: false,
      open_search: false,
      searchQuery: "",
      searchComplete: false,
      searchPage: 0,
      searchPages: 0,
      links: []
    };
  },
  components: {
    ShortlinkRow,
    CreateLink,
    Notification,
    Sidebar,
    paginate: Paginate,
  },
  computed: {
    ...mapGetters([
      "SOURCES",
      "OPTIONS",
      "SOURCESELECT",
      "DEPARTMENTS",
      "DEPARTMENT_SELECT",
      "QUANTITY_DEP",
    ]),
    dataAPI() {
      let d = {
        descending: true,
        perPage: this.perPage,
        page: +this.pageNum,
        departmentId: null,
      };
      this.DEPARTMENT_SELECT !== ""
          ? d.departmentId = +this.DEPARTMENT_SELECT : '';
      this.SOURCESELECT.account.id !== ""
          ? d.accountId = +this.SOURCESELECT.account.id : '';
      this.SOURCESELECT.media.id !== ""
          ? d.mediaId = +this.SOURCESELECT.media.id : '';
      this.placeFiltered !== "" ? d.placmentId = +this.placeFiltered : '';
      this.tagFiltered !== "" ? d.tagId = +this.tagFiltered : '';
      this.userFiltered != null ? d.userId = +this.userFiltered : '';
      this.domainFiltered !== "" ? d.domainId = +this.domainFiltered : '';
      this.idPublication !== "" ? d.contentId = +this.idPublication : '';
      this.startDate !== "" ? d.start = this.fdate(this.startDate) : '';
      this.endDate !== "" ? d.finish = this.fdate(this.endDate) : '';
      return d;
    },
    FilteredOption: function () {
      if (this.SOURCESELECT.media.id !== "") {
        let options = this.OPTIONS.mediaInfo;
        return options.filter((media) => {
          return media.id === this.SOURCESELECT.media.id;
        });
      } else {
        return false;
      }
    },
    OQ() {
      return (
        this.DEPARTMENT_SELECT +
        (this.SOURCESELECT.media.id !== ""
          ? "&mId=" + this.SOURCESELECT.media.id
          : "") +
        (this.SOURCESELECT.account.id !== ""
          ? "&accId=" + this.SOURCESELECT.account.id
          : "")
      );
    },
    pages() {
      if (this.links.length > 0 && this.links[0].quant > this.perPage) {
        return Math.ceil(this.links[0].quant / this.perPage);
      }
      return 0;
    },
  },
  methods: {
    clear_select() {
      let clear = {
        account: { id: "", data: "" },
        media: { id: "", name: "" },
      };
      this.$store.commit('SET_SOURCESELECT', clear);
      this.$store.commit('SET_DEPARTMENT_SELECT', '');
    },
    getNewLink() {
      this.getLinks()
      this.searchQuery = '';
      this.searchPages = 0;
      this.searchComplete = false;
    },
    showNotification(answer) {
      this.activeNotification = true;
      let vm = this;
      setTimeout(function () {
        vm.activeNotification = false;
        vm.notificationInfo = {};
        vm.notificationStatus = 0
      }, 20000);
      this.notificationStatus = answer.status;
      this.notificationInfo = typeof(answer.data) === 'object' ? answer.data : {info: answer.data} ;
    },
    setfilteredDate() {
      let d = this.range;
      d.setHours(d.getHours() + 3);
      this.dateFiltered = d.toISOString();
    },
    clearDate() {
      this.startDate = "";
      this.endDate = "";
    },
    openModal() {
      document.querySelector("body").style.overflow = "hidden";
    },
    closeModal() {
      document.querySelector("body").removeAttribute("style");
      this.$store.dispatch('GET_OPTIONS', this.OQ);
      this.getLinks()
    },
    singleCheck() {
      if (Object.keys(this.SOURCES).length === 1) {
        if (Object.keys(this.SOURCES[0].accounts).length === 1) {
          this.$store.commit('SET_SOURCESELECT', {
            media: this.SOURCES[0],
            account: this.SOURCES[0].accounts[0]
          })
          this.singleAcc = true;
        } else {
          this.$store.commit('SET_SOURCESELECT', {
            media: this.SOURCES[0],
            account: {
              id: '',
              data: '',
            }
          })
        }
      }
      if (this.QUANTITY_DEP === 1) {
        let d,
            dkey = Object.keys(this.DEPARTMENTS);

        dkey.map(Key => d = Key);
        this.$store.commit('SET_DEPARTMENT_SELECT', d);
        this.$store.dispatch('GET_SOURCES', d);
        this.showCreate = true;
        this.openModal();
      }
    },
    fdate(d = null) {
      if (d === null) {
        return false
      } else {
        let fd = d.setHours(d.getHours() + 3);
        let fdd = new Date(fd);
        return fdd.toJSON();
      }
    },
    onPageChange(page) { this.pageNum = page },
    onSearchPageChange(page) { this.searchPage = page; this.searchLink(page) },
    searchLink(page) {
      let d = "?pattern=" + this.searchQuery;
      page ? d += '&page=' + page : '';
      this.DEPARTMENT_SELECT !== ""
        ? (d += "&DepartmentId=" + this.DEPARTMENT_SELECT) : "";
      this.SOURCESELECT.media.id !== ""
        ? (d += "&MediaId=" + this.SOURCESELECT.media.id) : "";
      this.SOURCESELECT.account.id !== ""
        ? (d += "&AccountId=" + this.SOURCESELECT.account.id) : "";
      this.searchQuery !== "" ? (this.searchComplete = true) : "";

      this.$axios.get('/user/searchLinks' + d)
          .then(res => {
            this.links = res.data.items
            this.searchPage = res.data.page
            this.searchPages = res.data.pages
          })
          .catch((error) => {
            if (error.response.status === 401) this.$store.dispatch('LOGOUT')
            console.log(error);
          });

      this.open_search = false;
    },
    getLinks() {
      this.$axios.post('/user/getLinks', this.dataAPI)
          .then(res => this.links = res.data)
          .catch((error) => {
            if (error.response.status === 401) this.$store.dispatch('LOGOUT')
            console.log(error);
            this.$router.push("/adm/login")
          });
    }
  },
  mounted() {
    this.$store.dispatch('GET_OPTIONS', this.dep).then(() => this.singleCheck())
    this.getLinks()
  },
  watch: {
    range() { this.setfilteredDate() },
    OQ() { this.$store.dispatch('GET_OPTIONS', this.OQ) },
    dataAPI() { this.getNewLink() },
    pages() { if (this.searchQuery === '') this.pageNum = 1 },
  },
  beforeDestroy() {
    this.clear_select();
  },
};
</script>

<style>
a {
  text-decoration: underline;
}
ul {
  padding: 0;
}
input[type="button"] {
  background: inherit;
}
.show-enter-active {
  animation: show-in 0.5s;
}
.show-leave-active {
  animation: show-in 0.5s reverse;
}
@keyframes show-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

</style>
