<template>
  <div class="login_wrap">
    <div class="login">
      <span v-show="uncorrect" class="err-msg">Не првильно введен e-mail или пароль!</span>
      <form class="login_form" @submit.prevent="login">
        <input required v-model="email" type="email" placeholder="E-mail" class="login_input">
        <input v-model="password" type="password" placeholder="Пароль" class="login_input"
               >
        <input type="submit" value="LOGIN" class="login_btn">
      </form>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
          email: '',
          password: '',
          uncorrect: false
      };
    },
    methods: {
      login() {
        let user = {"email": this.email,"password":this.password}
        this.password == '' ? user.password = '1': '';
		   	this.$store.dispatch('login', user)
         .then((res) => {
           this.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.token
           this.$router.push('/')
         })
         .catch(err => {
           if (err.response.data === 2) this.$router.push('/adm/resetpassword')
           if (err.response.data === 1 || 4) this.uncorrect = true
          })
        }
      }
    };

</script>

<style></style>
