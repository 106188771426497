<template>
  <transition>
    <div>
      <div class="modal">
        <svg class="modal__cross" @click="$emit('close')" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z" /></svg>
        <div class="modal__wrapper">
          <div class="add-shortlink_title">
            <span>Создание нового медийного аккаунта</span>
        </div>
        <div class="modal__content">
          <form @submit.prevent="createAccount" class="form100">
          <input
            required
            type="text"
            v-model="name"
            class="add-shortlink_url add-shortlink_input"
            placeholder="Наименование аккаунта"
          />
          <div class="add-shortlink_item">
            Выберите департамент
            <select v-model="department" class="input_box add-shortlink_select" required>
                <option v-for="(dep,key) in departments" :key="key" :value="key"> {{dep}}</option>
            </select>
          </div>
          <div class="add-shortlink_item" required>
            Выберите медиа
            <select v-model="set_media" class="input_box add-shortlink_select" required :disabled="department == ''">
                <option v-for="(med,key) in media" :key="key" :value="key"> {{med}}</option>
            </select>
          </div>
          <div class="shortlink_done_btn">
            <input type="submit" value="OK">
          </div>
        </form>
        </div>
        </div>
      </div>
      <div class="overlay overlay-modal" @click="$emit('close')"></div>
    </div>
  </transition>
</template>

<script>
export default {
	data() {
		return {
        name: '',
        department: '',
        set_media: '',
        media: ''
			};
  },
  props: {
    departments: {
      type: Object,
      default() {
        return {};
      },
    },
  },
	methods: {
      createAccount() {
        let data = {
          "name": this.name,
          "departmentId": +this.department,
          "mediaId": +this.set_media,
          "add": true
        }

        this.$axios.post('/admin/editaccounttable', data)
            .then(() => this.$emit('close'))
      }
  },
  mounted() {
    this.$axios.get('/admin/getmedia')
        .then(media => {
          this.media = media.data
        })
        .catch((error) => {
          if (error.response.status === 401) this.$store.dispatch('LOGOUT')
          console.log(error);
        })
  }
};
</script>
