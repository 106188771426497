import {createRouter, createWebHistory} from 'vue-router'
import store from './store.js'
import Home from './views/Home.vue'
import Login from './pages/Login.vue'
import PasswordReset from './pages/PasswordReset.vue'
import Profile from './pages/Profile.vue'
import Department from './pages/Department.vue'
import Settings from './pages/Settings.vue'
import Register from './components/Register.vue'
import Statistics from './pages/Statistics.vue'
import Logs from './pages/Logs.vue'

const routes = [
  {
    path: '/adm/register',
    name: 'home',
    component: Home
  },
  {
    path: '/adm/login',
    name: 'login',
    component: Login
  },
  {
    path: '/adm/resetpassword',
    name: 'ResetPassword',
    component: PasswordReset
  },
  {
    path: '/adm/profile',
    name: 'Profile',
    component: Profile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/adm/register',
    name: 'register',
    component: Register
  },
  {
    path: '/adm/links',
    name: 'Department',
    alias: '/',
    component: Department,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/adm/statistics',
    name: 'Statistics',
    component: Statistics,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/adm/settings',
    name: 'Settings',
    component: Settings,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/adm/logs',
    name: 'Logs',
    component: Logs,
    meta: {
      requiresAuth: true
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/adm/login')
  } else {
    next()
  }
})

export default router