<template>
	<div>
		<div class="notification" :class="{active: activeNotification == true}" >
			{{notificationInfo}}
		</div>
	</div>
</template>

<script>
export default {
	props: {
		activeNotification: Boolean,
		notificationInfo: String
	},
}
</script>
