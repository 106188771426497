<template>
  <div class="row manager-item">
    <div class="manager-row">
      <div class="row-item row-item_25">
        <span class="manager">
          {{manager.name}}
        </span>
      </div>
      <div class="row-item row-item_25">
        <a :href="`mailto:${manager.email}`" class="manager-email">
          {{manager.email}}
        </a>
      </div>
      <div class="row-item row-item_50">
        <div v-if="!manager.block" class="access_wrap">
          <div v-if="manager.isAdmin" class="manager-departments-admin">
            Все департаменты
            <span class="manager-admin">- Администратор</span>
          </div>
          <div v-else  class="manager-departments-admin column">
            <div v-for="(dep,i) in DepName" :key="i">
              {{dep}}
            </div>
          </div>
        </div>
      </div>
    </div>
      <div class="manager-row" v-if="!manager.block">
        <div class="row-item row-item_25" v-if="!manager.confirmed">
          <input
            type="submit"
            class="block-user_btn unblock "
            value="Подтвердить E-mail"
            @click="setConfirmed(manager.id)"
          />
        </div>
        <div class="row-item row-item_25" v-else>
          <input
            type="submit"
            class="block-user_btn"
            value="Сбросить пароль"
            @click="resetPassword(manager.id)"
          />
        </div>
        <div class="row-item row-item_25" >
          <input
            type="submit"
            class="block-user_btn"
            value="Заблокировать"
            @click="blockUser()"
          />
        </div>
        <div class="row-item row-item_25" v-if="!manager.isAdmin">
          <input
            type="submit"
            class="set-admin_btn"
            @click="editUser(0)"
            value="Назначить администратором"
          />
        </div>
        <div class="row-item row-item_25" v-if="!manager.isAdmin">  
          <input
            type="submit"
            class="set-acc_btn"
            @click="openModal('setaccount')"
            value="Назначить доступ"
          />
        </div>
        <div class="row-item row-item_25" v-else>
          <input
              type="submit"
              class="set-admin_btn"
              @click="editUser(1)"
              value="Снять админстраторский доступ"
          />
        </div>
      </div>
      <div class="manager-row" v-else>
        <div class="row-item row-item_25">
          <input
            type="submit"
            class="block-user_btn unblock "
            value="Разблокировать"
            @click="blockUser()"
          />
        </div>
      </div>
      <set-account 
        :manager="manager"
        v-if="showModal.setaccount" 
        @close="closeModal('setaccount')"
        @editUser="editUser"
        ></set-account>
      <!-- <reset-password
        :manager="manager"
        v-if="showModal.resetpassword" 
        @close="closeModal('resetpassword')"
        ></reset-password> -->
  </div>
</template>

<script>
import SetAccount from "./SetAccount";
// import ResetPassword from './ResetPassword';


export default {
  data() {
    return {
      showModal: {
        "setaccount": false,
        "resetpassword": false
      },
    };
  },
  components: {
    SetAccount
  },
  props: {
    manager: {
      type: Object,
      default() {
        return {};
      },
    },
    departments: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    DepName() {
      let dep = []
      for (let id of this.manager.access) {
        id === 0
            ? dep.push('Все департаменты')
            : dep.push(this.departments[id])
      }
      return dep
    }    
  },
  methods: {
    openModal(modal){
      this.showModal[modal] = true;
      document.querySelector('body').style.overflow = "hidden";
    },
    closeModal(modal){
      this.showModal[modal] = false;
      document.querySelector('body').removeAttribute('style');
    },
    resetPassword(manager) {
      this.$axios.get('/auth/adminresetpassword?mId=' + manager)
          .then(() => {
            this.$emit('showNotification', 'Пароль успешно сброшен')
          })
          .catch((error) => {
            console.log(error);
          });
    },
    editUser(admin) {
      let req = {}

      if (typeof(admin) === 'object') {
        req = admin
      } else {
        req = {
          userId: this.manager.id,
          departments: [admin]
        }
      }
      this.$axios.post('/admin/editmanageraccess', req)
          .then((answer) => {
            this.$emit('getManagersList')
            return answer;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    blockUser() {
      let user = { id: this.manager.id, block: !this.manager.block };
      this.$axios.post('/auth/blockuser', user)
          .then((answer) => {
            this.$emit('getManagersList')
            return answer;
          })
          .catch((error) => {
            console.log(error);
          });
    },
    setConfirmed(manager) {
      this.$axios.get('/auth/setconfirmed?id=' + manager)
          .then((answer) => {
            this.$emit('getManagersList')
            return answer;
          })
          .catch((error) => {
            console.log(error);
          });
    }
  },
};
</script>

<style>
.column {
  flex-direction: column;
}
</style>