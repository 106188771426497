import { createApp } from 'vue';
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueClipboard from 'vue3-clipboard'
import { DatePicker } from 'v-calendar'
import headerMain from "./components/HeaderMain.vue"
import Paginate from "vuejs-paginate-next";

const token = 'Bearer ' + localStorage.getItem('token')
if (token) {
  axios.defaults.headers.common['Authorization'] = token
}

const axiosInstance = axios.create({
  withCredentials: false,
  baseURL: 'https://go.ast.ru'
})

const app = createApp(App)

app.use(Paginate)
    .use(store)
    .use(router)
    .component('DatePicker', DatePicker)
    .component('HeaderMain', headerMain)

app.use(VueClipboard, {
  autoSetContainer: true,
  appendToBody: true,
})

// app.provide('axios', app.config.globalProperties.axios)
app.config.globalProperties.$axios = { ...axiosInstance }
app.mount('#app');
