<template>
  <transition>
    <div>
      <div class="modal">
        <svg class="modal__cross" @click="$emit('close')" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path
              d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"/>
        </svg>
        <div class="modal__wrapper">
          <div class="add-shortlink_title">
            <span>Назначение аккаунта для {{ manager.name }}</span>
          </div>
          <div class="modal__content">
            <form @submit.prevent="setAccess" class="form100">
              <div class="add-shortlink_item settings-modal__content">
                Выберите департаменты и аккаунты
                <div v-for="(d,i) in allAccounts" :key="i" class="hidden-checkbox__box">
                  <div class="settings-dep__wrapper">
                    <input :value="d.depId" type="checkbox" v-model.number="departments" :id="'dep'+d.depId"
                         class="hidden-checkbox" @change='addAcc(d.depId,d.media)'>
                    <label :for="'dep'+d.depId" class="hidden-checkbox__label">
                      {{ d.depName }}
                    </label>
                    <span style="margin-left: auto; cursor: pointer;" v-if="allAccSelected(d.media)" @click="unsetAccounts(d.media)">Снять отметку со всех аккаунтов</span>
                  </div>
                  <transition name="slide">
                    <div v-if="departments.includes(d.depId)">
                      <div v-for="(m,i) in d.media" :key="i" class="settings-dep__box">
                        <span class="media-name">{{ m.name }}</span>
                        <div class="hidden-checkbox__box" v-for="(a,i) in m.accounts" :key="i">
                          <input :value="a.id" type="checkbox" v-model.number="accountAccess" :id="'account'+a.id"
                                 class="hidden-checkbox">
                          <label :for="'account'+a.id" class="hidden-checkbox__label">
                            {{ a.name }}
                          </label>
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>
              </div>
              <div class="shortlink_done_btn">
                <input type="submit" value="OK">
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="overlay overlay-modal" @click="$emit('close')"></div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      departments: [],
      accountAccess: [],
      allAccounts: []
    };
  },
  props: {
    manager: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    allAccSelected(media) {
      let itsTrue = true;
      media.forEach(m => {
        m.accounts.forEach(acc => {
          if (!this.accountAccess.includes(acc.id)) itsTrue = false;
        })
      })
      return itsTrue
    },
    unsetAccounts(media) {
      media.forEach(m => {
        m.accounts.forEach(acc => {
          let pos = this.accountAccess.indexOf(acc.id);
          if (pos >= 0) this.accountAccess.splice(pos, 1);
        })
      })
    },
    setAccess() {
      let q = {
        userId: this.manager.id,
        departments: this.departments,
        accounts: this.accountAccess
      };
      this.$emit('editUser', q)
      this.$emit('close')
    },
    addAcc(id, media) {
      if (!this.departments.includes(id)) {
        for (let m of media) {
          for (let acc of m.accounts) {
            let pos = this.accountAccess.indexOf(acc.id);
            if (pos >= 0) this.accountAccess.splice(pos, 1);
          }
        }
      } else {
        for (let m of media) {
          for (let acc of m.accounts) {
            this.accountAccess.push(acc.id);
          }
        }
      }
    },
    checkIncludes(id) {
      for (let account of this.allAccounts) {
        if (account.depId === id) {
          let count = 0
          for (let media of account.media) {
            for (let acc of media.accounts) {
              this.accountAccess.includes(acc.id) ? count++ : '';
            }
          }
          count === 0 ? this.addAcc(id, account.media) : ''
        }
      }
    },
    checkAllDep() {
      if (this.manager.access === 0) {
        for (let acc of this.allAccounts) {
          this.departments.push(acc.depId)
        }
      } else {
        this.departments = this.manager.access
      }
    }
  },
  mounted() {
    this.$axios.get('/admin/getaccounts')
        .then(a => this.allAccounts = a.data)
        .then(() => {
          this.checkAllDep()
          for (let dep of this.departments) {
            this.checkIncludes(dep)
          }
        })
        .catch((error) => {
          // if (error.response.status === 401) this.$store.dispatch('LOGOUT')
          console.log(error);
        });
    this.accountAccess = this.manager.accounts || []
  }
};
</script>
