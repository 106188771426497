import {createStore} from 'vuex'
import axios from 'axios';

const urlAPI = 'https://go.ast.ru'; // Для билда

const store = createStore({
    state: {
        status: '',
        token: localStorage.getItem('token') || '',
        user: {
            email: localStorage.getItem('email') || '',
            name: localStorage.getItem('name') || '',
            isAdmin: localStorage.getItem('isAdmin') || '',
        },
        departments: [],
        quantityDep: '',
        sources: [],
        options: [],
        source_select: {
            account: {
                id: '',
                data: '',
            },
            media: {
                id: '',
                name: '',
            },
        },
        table: {},
        media: [],
        department_select: '',
    },
    mutations: {
        auth_request(state, e) {
            state.status = 'loading';
            state.user.email = e;
        },
        auth_success(state, data) {
            state.status = 'success';
            state.token = data.token;
            state.refreshToken = data.refreshToken;
            state.user.name = data.managerName;
            state.user.isAdmin = data.isAdmin;
        },
        auth_error(state) {
            state.status = 'error';
        },
        SET_USER_NAME(state, data) {
            localStorage.setItem('name', data.name);
            state.user.name = data.name;
        },
        SET_USER_EMAIL(state, data) {
            localStorage.setItem('email', data.email);
            state.user.email = data.email;
        },
        LOGOUT(state) {
            state.status = '';
            state.token = '';
        },
        SET_DEPARTMENTS: (state, departments) => {
            state.departments = departments;
            state.quantityDep = Object.keys(departments).length;
        },
        SET_SOURCES: (state, sources) => {
            state.sources = sources;
        },
        SET_OPTIONS: (state, options) => {
            state.options = options;
        },
        SET_SOURCESELECT: (state, source_select) => {
            state.source_select = source_select;
        },
        SET_TABLE: (state, table) => {
            state.table = table;
        },
        SET_MEDIA: (state, a) => {
            state.media = a;
        },
        SET_DEPARTMENT_SELECT: (state, dep) => {
            state.department_select = dep;
        },
    },
    actions: {
        login({commit}, user) {
            return new Promise((resolve, reject) => {
                commit('auth_request', user.email);
                localStorage.setItem('email', user.email);
                axios({url: urlAPI + '/Auth/LogIn', data: user, method: 'POST'})
                    .then((res) => {
                        localStorage.setItem('token', res.data.token);
                        localStorage.setItem('refreshToken', res.data.refreshToken);
                        localStorage.setItem('name', res.data.managerName);
                        localStorage.setItem('isAdmin', res.data.isAdmin);
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.token
                        commit('auth_success', res.data);
                        resolve(res);
                    })
                    .catch((err) => {
                        commit('auth_error');
                        localStorage.removeItem('token');
                        localStorage.removeItem('refreshToken');
                        reject(err);
                    });
            });
        },
        RESET_PASSWORD({commit}, user) {
            return new Promise((resolve, reject) => {
                axios.post(urlAPI + '/Auth/ResetPassword/', user)
                    .then(res => resolve(res))
                    .catch(err => reject(err));
            });
        },
        LOGOUT({commit}) {
            return new Promise((resolve) => {
                commit('LOGOUT');
                localStorage.removeItem('token');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('email');
                localStorage.removeItem('name');
                localStorage.removeItem('isAdmin');
                delete axios.defaults.headers.common['Authorization'];
                resolve();
            });
        },
        EDIT_CREDENTIALS({}, data) {
            return axios.post(urlAPI + '/auth/editCredentials', data)
                .catch((error) => {
                    if (error.response.status === 401) dispatch('LOGOUT');
                    console.log(error);
                });
        },
        GET_DEPARTMENTS({dispatch, commit}) {
            return axios.get(urlAPI + '/user/getDepartments')
                .then((res) => {
                    commit('SET_DEPARTMENTS', res.data);
                    return res;
                })
                .catch((error) => {
                    if (error.response.status === 401) dispatch('LOGOUT')
                    console.log(error);
                });
        },
        GET_SOURCES({commit}, dep) {
            return axios.get(urlAPI + '/user/getAccounts/' + dep)
                .then((res) => {
                    commit('SET_SOURCES', res.data);
                    return res;
                })
                .catch(error => console.log(error));
        },
        GET_OPTIONS({commit}, dep) {
            let urlDep = urlAPI + '/user/getOptions';
            if (dep) urlDep += '?depId=' + dep;

            return axios.get(urlDep)
                .then((options) => {
                    commit('SET_OPTIONS', options.data);
                    return options;
                })
                .catch(error => console.log(error));
        },
        GET_TABLE({commit}, acc) {
            return axios.get(urlAPI + '/user/GetTable/' + acc)
                .then((res) => {
                    commit('SET_TABLE', res.data)
                })
                .catch((error) => console.log(error));
        },

        // Данная функция не используется (но предположительно должна была быть использована в CreateLink)
        SET_VENDOR({}, link) {
            return axios.post(urlAPI + '/user/setVendor', link)
                .then((res) => {
                    return res.data
                })
                .catch(error => console.log(error));
        },
        // Данная функция не используется (но предположительно должна была быть использована в CreateLink)
        SET_PARTNER_ID({}, link) {
            return axios.post(urlAPI + '/user/setPartnerId', link)
                .then((res) => {
                    return res.data
                })
                .catch((error) => console.log(error))
        },
    },
    getters: {
        isLoggedIn: (state) => !!state.token,
        authStatus: (state) => state.status,
        DEPARTMENTS: (state) => state.departments,
        QUANTITY_DEP: (state) => state.quantityDep,
        SOURCES: (state) => state.sources,
        OPTIONS: (state) => state.options,
        TABLE: (state) => state.table,
        SOURCESELECT: (state) => state.source_select,
        USER: (state) => state.user,
        MEDIA: (state) => state.media,
        DEPARTMENT_SELECT: (state) => state.department_select
    },
});

export default store