<template>
  <transition>
    <div>
      <div class="modal">
        <svg class="modal__cross" @click="$emit('close')" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z" /></svg>
        <div class="modal__wrapper">
          <div class="add-shortlink_title">
            <span>Сократить ссылку</span>
            <a v-if="!!accountLink.id && !!accountLink && !singleAcc" @click="clearSelect">сбросить</a>
          </div>
          <div class="modal__content link-modal">
            <span v-if="!validParam.urlLink && !urlLink" class="add-shortlink__valid">Заполните URL для сокращения ссылки</span>
            <input
              type="url"
              v-model="urlLink"
              class="add-shortlink_url add-shortlink_input"
              :class="{'add-shortlink__error': !validParam.urlLink && !urlLink}"
              placeholder="Введите url"
              @change="getParameters()"
            />
          <div class="add-shortlink_item" v-if="$store.getters.USER.isAdmin">
            <div>
              Альянс домена
            </div>
            <div class="add-shortlink_row">
              <select
                  v-model="domainAlliance"
                  class="input_box add-shortlink_select"
              >
                <option v-for="(i,alliance) in options.domainAlliances" :key="i" :value="alliance"> {{alliance}}</option>
              </select>
              <input
                  type="text"
                  name="hashtag_add"
                  id="hashtag_add"
                  v-model="domainAllianceNew"
                  class="input_box add-shortlink_input ml"
                  placeholder="Введите, чтобы создать новый"
              />
            </div>
          </div>
          <div class="add-shortlink_item fr" v-else>
            Альянс домена: {{ domainAlliance || 'Не указан' }}
          </div>
          <div class="add-shortlink_item fr">
            Partner ID:
            <input
              type="url"
              v-model="partnerID"
              class="add-shortlink_url add-shortlink_input"
              placeholder="Partner Id"
            />
          </div>
            <div class="add-shortlink_item fr">
              Vendor ID:
              <input
                  type="url"
                  v-model="vendorID"
                  class="add-shortlink_url add-shortlink_input"
                  placeholder="Vendor Id"
              />
            </div>
          <div class="add-shortlink_item--half">
            <div>
              Источник
              <span v-if="!validParam.mediaLinkId && !mediaLinkId" class="add-shortlink__valid">Выберите источник</span>
            </div>
            <select
                v-model="mediaLinkId"
                class="input_box add-shortlink_select"
                v-if="!accountLink.id || !accountLink"
                :class="{'add-shortlink__error': !validParam.mediaLinkId && !mediaLinkId}"
            >
			        <option v-for="(source, key) in table.mediaInfo" :key="key" :value="source.id"> {{source.name}} </option>
            </select>
            <input type="text"  v-model="ArrayOption[0].name" class="input_box add-shortlink_input ml" v-if="!!accountLink.id && !!accountLink" disabled>
          </div>
          <div class="add-shortlink_item--half" v-if="!!mediaLinkId">
            <div>
              Аккаунт
              <span v-if="!validParam.accountLink && !accountLink.id" class="add-shortlink__valid">Выберите аккаунт</span>
            </div>
            <select
                v-model="accountLink"
                class="input_box add-shortlink_select"
                v-if="(!accountLink || !accountLink.id)"
                :class="{'add-shortlink__error': !validParam.accountLink && !accountLink.id}"
            >
			        <option v-if="!!ArrayOption[0].accounts" v-for="(account,key) in ArrayOption[0].accounts" :key="key" :value="account"> {{account.data}} </option>
            </select>
            <input type="text"  v-model="accountLink.data" class="input_box add-shortlink_input ml" v-if="!!accountLink.id && !!accountLink" disabled>
          </div>
          <div class="add-shortlink_item--half" v-if="!!accountLink.id">
            <div>
              Тип размещения
              <span v-if="!validParam.locationLinkId && !locationLinkId" class="add-shortlink__valid">Выберите тип размещения</span>
            </div>
            <select
                v-model="locationLinkId"
                class="input_box add-shortlink_select"
                :class="{'add-shortlink__error': !validParam.locationLinkId && !locationLinkId}"
            >
              <option v-for="(location,key) in table.allocations" :key="key" :value="location.id"> {{ location.data }} </option>
            </select>
          </div>
          <div class="add-shortlink_item--half" v-if="!!accountLink.id && !!accountLink && !!ArrayOption.length">
            <div>
              Место размещения
              <span v-if="!validParam.placmentLinkId && !placmentLinkId" class="add-shortlink__valid">Выберите место размещения</span>
            </div>
            <select
                v-model="placmentLinkId"
                class="input_box add-shortlink_select"
                :class="{'add-shortlink__error': !validParam.placmentLinkId && !placmentLinkId}"
            >
                <option v-for="(placment,key) in ArrayOption[0].placments" :key="key" :value="placment.id"> {{ placment.data }} </option>
            </select>
          </div>
          <div class="add-shortlink_item">
            <div>
              Акция/Тема контента
              <span v-if="!validParam.hashtagLinkId && !hashtagLinkId" class="add-shortlink__valid">Выберите или введите акцию/тему контента</span>
            </div>
            <div class="add-shortlink_row">
              <select
                  v-model="hashtagLinkId"
                  class="input_box add-shortlink_select"
                  :class="{'add-shortlink__error': !validParam.hashtagLinkId && !hashtagLinkId}"
              >
                <option v-for="(i,tag) in options.tags" :key="i" :value="i"> {{tag}} </option>
              </select>
              <input
                  type="text"
                  name="hashtag_add"
                  id="hashtag_add"
                  v-model="addHashtag"
                  class="input_box add-shortlink_input ml"
                  :class="{'add-shortlink__error': !validParam.hashtagLinkId && !hashtagLinkId}"
                  placeholder="Введите, чтобы создать новую"
              />
            </div>
          </div>
          <div class="add-shortlink_item--half add-shortlink_item--mobile">
            <div class="add-shortlink_title-row">
              <label class="add-shortlink_title-box">
                <input type="radio" name="supplier" class="slider" :value="true" v-model="supplierArticleToggle">
                <span>Код товара</span>
              </label>
              <label class="add-shortlink_title-box">
                <input type="radio" name="supplier" class="slider" :value="false" v-model="supplierArticleToggle">
                <span>Пакет товаров</span>
              </label>
            </div>
            <input
                v-if="supplierArticleToggle"
                type="text"
                v-model="supplierArticle"
                class="input_box add-shortlink_input"
                placeholder="Введите код товара [не обязательно]"
            />
            <select
                v-else
                v-model="promoPacket"
                class="input_box add-shortlink_select"
                placeholder="Выберите пакет товаров [не обязательно]"
            >
              <option v-for="item in promoPackets" :key="item.c_packet" :value="item.c_packet"> {{item.packet}} </option>
            </select>
          </div>
          <div class="add-shortlink_item--half add-shortlink_item--mobile">
            Комментарий
            <input
              type="text"
              v-model="comment"
              class="input_box add-shortlink_input"
              placeholder="Введите комментарий [не обязательно]"
            />
          </div>
          <div class="add-shortlink_item--half">
            ID в контент-плане
            <input
              type="number"
              v-model="contentPlanId"
              class="input_box add-shortlink_input"
              placeholder="Введите ID из контент-плана (только цифры!) [не обязательно]"
            />
          </div>
          <div class="add-shortlink_item--half">
            UTM Term
            <input
              type="text"
              v-model="utmTerm"
              class="input_box add-shortlink_input"
              placeholder="Введите UTM Term [не обязательно]"
            />
          </div>
          </div>
          <div class="shortlink_done_btn">
            <input type="submit" value="Сократить ссылку" @click="createLink">
        </div>
      </div>
        </div>
      <div class="overlay overlay-modal" @click="$emit('close')"></div>
    </div>
  </transition>
</template>

<script>
export default {
	data() {
		return {
      urlLink: '',
      mediaLinkId: '',
      accountLink: '',
			locationLinkId: '',
      placmentLinkId: '',
			hashtagLinkId: '',
			contentPlanId: '',
      supplierArticle: '',
      supplierArticleToggle: true,
      comment: '',
      addHashtag: '',
      utmTerm: '',
      partnerID: '',
      vendorID: '',
      domainAlliance: '',
      domainAllianceNew: '',
      promoPackets: [],
      promoPacket: '',
      ArrayOption: [{
        "id":'',
        "name":'',
        "accounts":[],
          "placments":[]
      }],
      validParam: {
        accountLink: true,
        urlLink: true,
        mediaLinkId: true,
        placmentLinkId: true,
        hashtagLinkId: true,
        locationLinkId: true
      },
    }
  },
  props: {
    singleAcc: Boolean
  },
	computed: {
    table() { return this.$store.getters.TABLE },
    options() { return this.$store.getters.OPTIONS },
    departmentSelect() { return this.$store.getters.DEPARTMENT_SELECT },
    oldTag(){
      const tt = this.addHashtag;
      return this.table.tags.filter(function (elem) {
          return elem.data.toLowerCase().indexOf(tt.toLowerCase()) > -1;
      })
    },
    OQ() {
      return this.departmentSelect + (!!this.mediaLinkId ? '&mId=' +this.mediaLinkId : '') + (!!this.accountLink.id ? '&accId=' +this.accountLink.id : '')
    }
  },
	methods: {
		createLink() {
      if (!!this.addHashtag) {
        return new Promise((resolve) => {
          if(this.oldTag.length === 1) {
              this.hashtagLinkId = this.oldTag[0].id
              resolve()
          } else {
            this.$axios.post('/user/createTag', { text: this.deleteSymbols(this.addHashtag) })
                .then((answer) => {
                  this.hashtagLinkId = answer.data
                  resolve()
                })
          }
        }).then(() => {
          this.sendLink()
        })
      } else {
        this.sendLink()
      }
    },
    deleteSymbols(str) {
      return str
          .replaceAll('\\t', '')
          .replaceAll('\\n', '')
          .replaceAll('\\r', '')
          .replaceAll(String.fromCharCode(160), '')
          .replaceAll('%20',  '')
          .replaceAll('%C2%A0',  '')
    },
    sendLink() {
      this.valid()
      let validForm = Object.values(this.validParam).every((i) => !!i)
      if (validForm) {
        let link = {
          "accountId":+this.accountLink.id,
          "url": this.deleteSymbols(this.urlLink),
          "placmentId":+this.placmentLinkId,
          "tagId": +this.hashtagLinkId,
          "contentPlanId":+this.contentPlanId,
          "alloctId":+this.locationLinkId,
          "contentId":1
        }
        if (this.$store.getters.USER.isAdmin && this.domainAllianceNew) {
          link['domainAlliance'] = this.domainAllianceNew
        } else if(this.domainAlliance) {
          link['domainAlliance'] = this.domainAlliance
        }
        if (this.supplierArticleToggle ) {
          this.supplierArticle ? link['SupplierArticle'] = this.deleteSymbols(this.supplierArticle) : ''
        } else {
          this.promoPacket ? link['packetId'] = +this.promoPacket : ''
        }
        this.partnerID !== '' ? link['comment'] =  this.comment + '\n' + this.partnerID : link['comment'] =  this.comment;
        this.utmTerm !== '' ? link['utmTerm'] = this.deleteSymbols(this.utmTerm) : '';
        this.vendorID !== '' ? link['VendorName'] = this.vendorID.toString() : '';

        this.$axios.post('/user/createLink', link)
            .then((answer) => {
              this.$copyText(answer.data.shortUrl)
              this.$emit('showNotification', answer)
            })
            .then(() => this.$emit('close'))
            .catch((err) => {
              err.response !== undefined
                  ? this.$emit('showNotification', err.response)
                  : this.$emit('showNotification', {status: 0, data: 'Произошла неизвестная ошибка'})
            })
      }
    },
    clearSelect() {
      this.mediaLinkId = ''
      this.accountLink = ''
    },
    getParameters() {
      let u = new URL(this.urlLink),
          ua = u.host,
          domain = '';
      this.urlLink = u.href

      if (ua.split('.').length >= 3) {
        domain = ua.substring(ua.indexOf('.')+1)
      } else {
        domain = u.host
      }

      let link = '?depId=' + this.departmentSelect + '&url=' + domain

      this.$axios.get('/user/getPartnerId' + link)
          .then(res => this.partnerID = res.data)
      this.$axios.get('/user/getVendor' + link)
          .then(res => this.vendorID = res.data)
      this.$axios.get('/user/getAlliance?domain=' + domain)
          .then(res => this.domainAlliance = res.data)
    },
    optionFilter() {
      if (!!this.table.mediaInfo && !!this.table.mediaInfo.length) {
        this.ArrayOption = this.table.mediaInfo.filter(option => {
          return this.mediaLinkId === '' || option.id === this.mediaLinkId;
        })
      }
    },
    valid() {
      this.validParam.accountLink = !!this.accountLink.id
      this.validParam.urlLink = !!this.urlLink
      this.validParam.mediaLinkId = !! this.mediaLinkId
      this.validParam.placmentLinkId = !!this.placmentLinkId
      this.validParam.hashtagLinkId = !!this.hashtagLinkId
      this.validParam.locationLinkId = !!this.locationLinkId
    },
  },
  watch: {
    supplierArticleToggle() {
      this.$axios.get('https://es.ast.ru/Packet/GetActual')
          .then(res => this.promoPackets = res.data)
    },
    mediaLinkId() {
      this.locationLinkId = ''
      this.placmentLinkId = ''
      this.optionFilter()
    },
    OQ() {  //Options Query
      this.$store.dispatch('GET_OPTIONS', this.OQ)
    },
  },
	mounted() {
    this.mediaLinkId = this.$store.getters.SOURCESELECT.media.id
    this.accountLink = this.$store.getters.SOURCESELECT.account
    this.$store.dispatch('GET_TABLE', this.departmentSelect).then(() => this.optionFilter())
	}
};
</script>
