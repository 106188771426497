<template>
  <transition>
    <div>
      <div class="modal">
        <svg class="modal__cross" @click="$emit('close')" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z" /></svg>
        <div class="modal__wrapper">
          <div class="add-shortlink_title">
            <span>Создание нового места размещения</span>
        </div>
        <div class="modal__content">
          <form @submit.prevent="addPlacement" class="form100">
            <input
              required
              type="text"
              v-model="name"
              class="add-shortlink_url add-shortlink_input"
              placeholder="Наименование места размещения"
            />
            <input
                required
                type="text"
                v-model="translate"
                class="add-shortlink_url add-shortlink_input"
                placeholder="Наименование места по-английски"
            />
            <div class="add-shortlink_item">
              Выберите департамент
              <select v-model="department" class="input_box add-shortlink_select" required>
                  <option v-for="(dep,key) in departments" :key="key" :value="key"> {{dep}}</option>
              </select>
            </div>
            <div class="add-shortlink_item" required>
              Выберите медиа
              <select v-model="set_media" class="input_box add-shortlink_select" required :disabled="department == ''">
                  <option v-for="med in sources" :key="med.id" :value="med.id"> {{med.name}}</option>
              </select>
            </div>
          <div class="shortlink_done_btn">
            <input type="submit" value="OK">
          </div>
        </form>
        </div>
        </div>
      </div>
      <div class="overlay overlay-modal" @click="$emit('close')"></div>
    </div>
  </transition>
</template>

<script>
export default {
	data() {
		return {
        name: '',
        department: '',
        set_media: '',
        translate: ''
			};
  },
  props: {
    departments: {
      type: Object,
      default() {
        return {};
      },
    },
  },
	computed: {
    sources() {
      return this.$store.getters.SOURCES
    }
  },
	methods: {
    addPlacement() {
        let data = {
          "name": this.name,
          "translate": this.translate,
          "mediaId": +this.set_media
        }
        this.$axios.post('/admin/addplacment', data)
            .then(() => this.$emit('close'))
      },
    traslit(text) {
      return text.replace(/([а-яё])|([\s_-])|([^a-z\d])/gi,
          function (all, ch, space, words) {
            if (space || words) {
              return space ? '-' : '';
            }
            let code = ch.charCodeAt(0),
                index = code === 1025 || code === 1105 ? 0 :
                    code > 1071 ? code - 1071 : code - 1039,
                t = ['yo', 'a', 'b', 'v', 'g', 'd', 'e', 'zh',
                  'z', 'i', 'y', 'k', 'l', 'm', 'n', 'o', 'p',
                  'r', 's', 't', 'u', 'f', 'h', 'c', 'ch', 'sh',
                  'shch', '', 'y', '', 'e', 'yu', 'ya'
                ];
            return t[index];
          }
      );
    }
  },
	watch: {
    department(){
      this.department != 0 ? this.$store.dispatch('GET_SOURCES', this.department) : ''
      this.set_media = ''
    },
    name() {
      this.translate = this.traslit(this.name)
    },
  },
};
</script>
