<template>
	<div>
    <div v-if="notificationStatus != 200" class="notification error" :class="{active: activeNotification == true}"  >
      Произошла ошибка: <br>
      {{notificationStatus}} - <span v-html="notificationInfo.info"></span>
    </div>
		<div v-else-if="!notificationInfo.isExist" class="notification" :class="{active: activeNotification == true}" >
			Ссылка успешно сокращена и скопирована: <br>
			{{notificationInfo.shortUrl}}
		</div>
		<div v-else-if="notificationInfo.isExist" class="notification" :class="{active: activeNotification == true}" >
			Даная ссылка была сокращена ранее: <br>
			{{notificationInfo.shortUrl}} <br>
			Сокращенная ссылка скопирована.
		</div>
	</div>
</template>

<script>
export default {
	props: {
		activeNotification: Boolean,
		notificationInfo: Object,
    notificationStatus: Number,
	},
}
</script>

<style scoped>
.notification.error {
  background: red;
  z-index: 9999;
}
</style>
