<template>
  <div class="content-wrap">
    <header-main :title_name="'Настройки'"></header-main>
    <section class="content content-wrapper">
      <div class="container">
        <section
          class="section_wrapper"
          style="margin-bottom: 30px"
        >
          <div class="row jsb">
            <a @click="openModal((showUser = true))">Создать доступ</a>
            <a @click="openModal((showMedia = true))">Создать медиа</a>
            <a @click="openModal((showAccount = true))">Создать медийный аккаунт</a>
            <a @click="openModal((showPlacement = true))">Создать место размещения</a>
          </div>
          <div class="section_title">Настройки доступов</div>
          <div class="row settings_filter-row">
            <div class="links_settings-item settings_filter select-arrow">
              Сортировать по:
              <select
                name="managers"
                id="sortManagers"
                class="select-setting"
                v-model="managersSort"
              >
                <option value="id-desc">↓ ID</option>
                <option value="id-asc">↑ ID</option>
                <option value="name-desc">↓ ФИО</option>
                <option value="name-asc">↑ ФИО</option>
                <option value="email-desc">↓ E-mail</option>
                <option value="email-asc">↑ E-mail</option>
                <option value="isAdmin-desc">↓ Доступу</option>
                <option value="isAdmin-asc">↑ Доступу</option>
                <option value="block-asc">Закрытым</option>
                <option value="block-desc">Не закрытым</option>
              </select>
            </div>
            <div class="links_settings-item settings_filter">
              Поиск по ФИО или E-mail:
              <input
                type="text"
                name="search"
                id="searchManager"
                class="input-setting input-setting__search"
                v-model="searchManager"
              />
            </div>
          </div>

          <manager-row
            v-for="(manager, key) in managersSorted"
            :key="key"
            :manager="manager"
            :departments="managersList.departments"
            @showNotification="showNotification"
            @getManagersList="getManagersList"
          />
        </section>
      </div>
    </section>
    <create-media
      v-if="showMedia"
      @close="closeModal((showMedia = false))"
    />
    <create-user
      v-if="showUser"
      @close="closeModal((showUser = false))"
    />
    <create-account
      :departments="managersList.departments"
      v-if="showAccount"
      @close="closeModal((showAccount = false))"
    />
    <create-placement
        :departments="managersList.departments"
        v-if="showPlacement"
        @close="closeModal((showPlacement = false))"
    />
    <notification
      :activeNotification="activeNotification"
      :notificationInfo="notificationInfo"
    />
  </div>
</template>
<script>
import CreateUser from "../components/CreateUser";
import ManagerRow from "../components/ManagerRow";
import CreateAccount from "../components/CreateAccount";
import CreateMedia from "../components/CreateMedia.vue";
import Notification from "../components/NotificationInfo";
import CreatePlacement from "../components/CreatePlacement";

export default {
  data() {
    return {
      activeNotification: false,
      notificationInfo: "",
      showUser: false,
      showAccount: false,
      showMedia: false,
      showPlacement: false,
      managersSort: localStorage.getItem("managersSort") || "id-desc",
      managersSorted: [],
      searchManager: "",
      managersList: []
    };
  },
  components: {
    CreateUser,
    ManagerRow,
    CreateMedia,
    CreateAccount,
    Notification,
    CreatePlacement
  },
  methods: {
    openModal() {
      document.querySelector("body").style.overflow = "hidden";
    },
    closeModal() {
      document.querySelector("body").removeAttribute("style");
    },
    showNotification(answer) {
      this.activeNotification = true;
      let vm = this;
      setTimeout(function () {
        vm.activeNotification = false;
      }, 20000);
      this.notificationInfo = answer;
    },
    SM() {
      let sortBy = this.managersSort.split("-").pop(),
        sortName = this.managersSort.split("-")[0];
      if (sortName == "block") {
        this.managersSorted.sort((a, b) => (a["name"] > b["name"] ? 1 : -1));
        sortBy == "desc"
          ? this.managersSorted.sort((a, b) => Number(a["block"]) - Number(b["block"]))
          : this.managersSorted.sort((a, b) => Number(b["block"]) - Number(a["block"]));
      } else {
        sortBy == "desc"
          ? this.managersSorted.sort((a, b) => a[sortName] > b[sortName] ? 1 : -1)
          : this.managersSorted.sort((a, b) => a[sortName] < b[sortName] ? 1 : -1);
      }
    },
    Search() {
      if (this.searchManager != "") {
        this.managersSorted = this.managersList.managers.filter((item) => {
          return Object.values(item).some((val) => val
              ? val.toString().toLowerCase().includes(this.searchManager.toLowerCase())
              : false
          );
        });
      } else {
        this.managersSorted = this.managersList.managers;
      }
      this.SM();
    },
    async getManagersList() {
      await this.$axios.get('/admin/getmanagerlist')
          .then((managers) => {
            this.managersList = managers.data
            this.SM()
            this.Search();
          })
          .catch((error) => {
            if (error.response.status === 401) {
              this.$store.dispatch('LOGOUT')
            }
            console.log(error)
          });
    }
  },
  watch: {
    managersSort() {
      this.SM();
      localStorage.setItem("managersSort", this.managersSort);
    },
    searchManager() {
      this.Search();
    },
  },
  mounted() {
    this.getManagersList()
  },
};
</script>

<style scoped>
.row.jsb {
  justify-content: space-between;
}
</style>
