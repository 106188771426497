<template>
  <div class="sidebar" :class="{ sidebar_open }">
    <svg class="modal__cross desktop-hide" @click="$emit('close')" xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 24 24">
      <path
          d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"/>
    </svg>
    <div v-for="dep in DEPARTMENTS" :key="dep.Key">
      <div
          @click="select_dep(dep.Key)"
          class="source-item source-item__dep"
          :class="{active: DEPARTMENT_SELECT == dep.Key}">
        {{ dep.Value }}
      </div>
      <div v-if="DEPARTMENT_SELECT == dep.Key">
        <div v-for="(source, key) in SOURCES" :key="key">
          <div
              @click="select_media(source)"
              class="source-item source-item__media"
              :class="{active: SOURCESELECT.media.id === source.id}">
            {{ source.name }}
          </div>
          <div v-if="SOURCESELECT.media.id === source.id">
            <div
                v-for="account in source.accounts"
                :key="account.id"
                @close="$emit('close')"
                class="source-item source-item__acc"
                :class="{active: SOURCESELECT.account.id === account.id}"
                @click="select_account(account)">
              {{ account.data }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  data() {
    return {}
  },
  props: {
    sidebar_open: Boolean
  },
  computed: {
    ...mapGetters([
      'DEPARTMENTS',
      'DEPARTMENT_SELECT',
      'SOURCESELECT',
      'SOURCES'
    ])
  },
  methods: {
    select_dep(dep) {
      let clear = {account: {id: '', data: ''}, media: {id: '', name: ''}};
      this.$store.commit('SET_SOURCESELECT', clear)
      this.$store.commit('SET_DEPARTMENT_SELECT', dep)
      this.$store.dispatch('GET_SOURCES', dep)
      this.$emit('clearFilter')
    },
    select_media(media) {
      this.$store.commit('SET_SOURCESELECT', {media: media, account: {id: '', data: ''}})
      this.$emit('clearFilter')
    },
    select_account(account) {
      this.$store.commit('SET_SOURCESELECT', {media: this.SOURCESELECT.media, account: account});
      this.$emit('close')
    }
  }
}
</script>