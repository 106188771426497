<template>
  <transition>
    <div>
      <div class="modal">
        <svg
          class="modal__cross"
          @click="$emit('close')"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
        >
          <path
            d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z"
          />
        </svg>
        <div class="modal__wrapper">
          <div class="add-shortlink_title">
            <span>Создание нового аккаунта менеджера</span>
          </div>
          <div class="modal__content">
            <form @submit.prevent="checkFormComplete" class="form100">
              <span
                style="color: red; font-size: 12px; margin-bottom: 5px"
                v-if="notification != ''"
                >{{ notification }}</span
              >
              <input
                type="text"
                v-model="name"
                class="add-shortlink_url add-shortlink_input"
                placeholder="Имя"
              />
              <input
                type="text"
                v-model="surname"
                class="add-shortlink_url add-shortlink_input"
                placeholder="Фамилия"
              />
              <input
                type="text"
                v-model="patronymic"
                class="add-shortlink_url add-shortlink_input"
                placeholder="Отчество"
                v-if="!nopatronymic"
              />
              <div class="fio__output" v-if="name || surname">
                Менеджер: {{ surname }} {{ name }} {{ patronymic }}
                <label
                  for="nopatronymic"
                  class="nopatronymic__label"
                  v-if="!patronymic"
                >
                  <input
                    type="checkbox"
                    v-model="nopatronymic"
                    id="nopatronymic"
                  />
                  Менеджер без отчества
                </label>
              </div>

              <input
                required
                type="email"
                v-model="email"
                class="add-shortlink_url add-shortlink_input"
                placeholder="E-mail менеджера"
              />
              <input
                required
                type="text"
                v-model="password"
                class="add-shortlink_url add-shortlink_input"
                placeholder="Пароль для аккаунта"
              />
              <div class="add-shortlink_item">
                Тип пользователя
                <select v-model="admin" class="input_box add-shortlink_select">
                  <option value="1">Администратор</option>
                  <option value="0">SMM менеджер</option>
                </select>
              </div>
              <div class="add-shortlink_item" v-if="admin != 1">
                Укажите доступ
                <div
                  v-for="(d, i) in allAccounts"
                  :key="i"
                  class="hidden-checkbox__box"
                >
                  <input
                    :value="d.depId"
                    type="checkbox"
                    v-model.number="departments"
                    :id="'dep' + d.depId"
                    class="hidden-checkbox"
                    @change="addAcc(d.depId, d.media)"
                  />
                  <label :for="'dep' + d.depId" class="hidden-checkbox__label">
                    {{ d.depName }}
                  </label>
                  <transition name="slide">
                    <div v-if="departments.includes(d.depId)">
                      <div
                        v-for="(m, i) in d.media"
                        :key="i"
                        class="settings-dep__box"
                      >
                        <span class="media-name">{{ m.name }}</span>
                        <div
                          class="hidden-checkbox__box"
                          v-for="(a, i) in m.accounts"
                          :key="i"
                        >
                          <input
                            :value="a.id"
                            type="checkbox"
                            v-model="accountAccess"
                            :id="'account' + a.id"
                            class="hidden-checkbox"
                          />
                          <label
                            :for="'account' + a.id"
                            class="hidden-checkbox__label"
                          >
                            {{ a.name }}
                          </label>
                        </div>
                      </div>
                    </div>
                  </transition>
                </div>
              </div>
              <div class="shortlink_done_btn">
                <input type="submit" value="OK" />
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="overlay overlay-modal" @click="$emit('close')"></div>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      name: "",
      surname: null,
      patronymic: null,
      email: "",
      password: "",
      departments: [],
      admin: 1,
      accountAccess: [],
      notification: "",
      nopatronymic: false,
      allAccounts: []
    };
  },
  computed: {
    fullName() {
      return this.nopatronymic
          ? (this.surname + " " + this.name).trim()
          : (this.surname + " " + this.name + " " + this.patronymic).trim()
    }
  },
  methods: {
    createUser() {
      let data = {
        name: this.fullName,
        email: this.email,
        password: this.password,
        isAdmin: this.admin === 1 ? true : false,
        departmnets: this.departments,
        accounts: this.accountAccess
      }
      this.$axios.post('/auth/createnewuser', data)
          .then(() => location.reload())
          .catch(error => console.log(error));
    },
    addAcc(id, media) {
      if (!this.departments.includes(id)) {
        for (let m of media) {
          for (let acc in m.accounts) {
            let pos = this.accountAccess.indexOf(acc.id);
            if (pos >= 0) this.accountAccess.splice(pos, 1);
          }
        }
      } else {
        for (let m of media) {
          for (let acc of m.accounts) {
            this.accountAccess.push(acc.id);
          }
        }
      }
    },
    checkFormComplete() {
      if (!(this.surname && this.name && (this.patronymic || this.nopatronymic))) {
        return this.notification = "Некорректно указано ФИО, введите ФИО менеджера и повторите попытку!";
      } 
      if (this.password.length < 6) {
        return this.notification = 'Пароль должен состоять не менее чем из 6 символов!'
      }
      this.createUser();
    },
  },
  mounted() {
    this.$axios.get('/admin/getaccounts')
        .then(a => this.allAccounts = a.data)
        .catch((error) => {
          if (error.response.status === 401) this.$store.dispatch('LOGOUT')
          console.log(error);
        });
  }
};
</script>
<style>
.fio__output {
  display: inline-block;
  margin-bottom: 18px;
}
.nopatronymic__label {
  margin-left: 30px;
}
</style>