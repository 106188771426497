<template>
  <div class="home">
    <div id="nav">
      <router-link to="/login" class="home__action_link">Войти</router-link>
      <router-link to="/login" class="home__action_link">Зарегистрироваться</router-link>
    </div>
  </div>
</template>

<script>
 export default {}
</script>

<style>
#nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.home__action_link {
  text-decoration: none;
  color: #333;
  border: 1px solid #333;
  border-radius: 4px;
  padding: 10px 0;
  margin: 10px 0;
  min-width: 300px;
  text-align: center;
  transition: .3ms ease;
}
.home__action_link:hover {
  background: #333;
  color: #fff;
}
</style>